import * as Styled from '../OrderDetails.styles';
import { useContext } from 'react';
import { CheckoutContext } from 'src/contexts';
import { StyledValue } from '../Pickup';

export const Buyer = () => {
  const { answers: checkout } = useContext(CheckoutContext);
  const { owner, user, coOwner } = checkout;
  if (!owner || !user) return null;

  return (
    <>
      <Styled.BreakdownHeaderRow sx={{ marginBottom: '24px' }}>
        <Styled.BreakdownHeader>Buyer name</Styled.BreakdownHeader>
        <StyledValue variant="body2">
          {owner.firstName} {owner.lastName}
        </StyledValue>
      </Styled.BreakdownHeaderRow>
      <Styled.BreakdownHeaderRow sx={{ marginBottom: coOwner ? '24px' : '0px' }}>
        <Styled.BreakdownHeader>Buyer email</Styled.BreakdownHeader>
        <StyledValue variant="body2">{user.email}</StyledValue>
      </Styled.BreakdownHeaderRow>
      {coOwner && (
        <>
          <Styled.BreakdownHeaderRow sx={{ marginBottom: '24px' }}>
            <Styled.BreakdownHeader>Co-buyer name</Styled.BreakdownHeader>
            <StyledValue variant="body2">
              {coOwner.firstName} {coOwner.lastName}
            </StyledValue>
          </Styled.BreakdownHeaderRow>
          <Styled.BreakdownHeaderRow sx={{ marginBottom: '24px' }}>
            <Styled.BreakdownHeader>Co-buyer email</Styled.BreakdownHeader>
            <StyledValue variant="body2">{coOwner.email}</StyledValue>
          </Styled.BreakdownHeaderRow>
        </>
      )}
    </>
  );
};
