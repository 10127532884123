export enum PaymentTypes {
  CASH = 'CASH',
  FINANCE = 'FINANCE',
}

export enum OrderPaymentTypes {
  FEE = 'FEE',
  CREDIT = 'CREDIT',
  FINANCE = 'FINANCE',
}
