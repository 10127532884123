import { LoadingDots } from '@clutch/torque-ui';
import type { ButtonProps as MuiButtonProps } from '@mui/material';
import { Button as MuiButton, useMediaQuery, useTheme } from '@mui/material';
import type { MouseEventHandler } from 'react';

export type ButtonProps = MuiButtonProps & {
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  rounded?: boolean;
  debounce?: number;
  responsive?: boolean;
  component?: React.ElementType | string;
  to?: string;
};

export const Button = ({
  loading = false,
  rounded = true,
  responsive = true,
  sx,
  children,
  onClick,
  color,
  disabled,
  ...rest
}: ButtonProps) => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const isDarkBackground = color == 'primary' || color == 'secondary'; // these two are the darker backgrouns
  const getButtonSize = () => {
    if (isMobile && responsive) {
      return 'medium';
    }

    return 'large';
  };

  const evaluatedSx = typeof sx === 'function' ? sx(muiTheme) : sx;

  // We want to wrap the onClick event, so we can debounce
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(e);
  };

  return (
    <MuiButton
      disableRipple
      disabled={loading || disabled}
      sx={{
        whiteSpace: 'nowrap',
        ...(!rounded && { borderRadius: '8px' }),
        ...(responsive && {
          [muiTheme.breakpoints.down('xs')]: {
            padding: '8px 16px',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 700,
            fontStyle: 'normal',
          },
        }),
        ...evaluatedSx,
      }}
      size={getButtonSize()}
      onClick={handleClick}
      color={color}
      {...rest}
    >
      {loading ? <LoadingDots color={isDarkBackground ? 'white' : 'black'} /> : children}
    </MuiButton>
  );
};

export default Button;
