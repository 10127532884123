import { getBenefitsBreakdown } from '@clutch/clutch-common/lib/utils/package-benefits';
import { useBooleanState } from '@clutch/hooks';
import { Collapse } from '@mui/material';

import { MoneyMask } from 'src/helpers/masks';
import { type Order, OrderProtection, OrderProtectionType } from 'src/types/api';

import { BreakdownButton } from '../BreakdownButton';
import * as Styled from '../OrderDetails.styles';

export type PurchaseValueProps = {
  purchaseValueBiWeekly: number;
  clutchPlanSubtotal: number;
  vehiclePrice: number;
  clutchPackagePriceWithWarranty: number;
  protectionDiscounts: Record<string, number>;
  order: Order;
  isFinancing: boolean;
  expand?: boolean;
};

export const PurchaseValue = ({
  purchaseValueBiWeekly,
  clutchPlanSubtotal,
  vehiclePrice,
  clutchPackagePriceWithWarranty,
  protectionDiscounts,
  order,
  isFinancing,
  expand = false,
}: PurchaseValueProps) => {
  const purchaseValueExpandedState = useBooleanState();
  const packageBenefitsList = getBenefitsBreakdown({ order, clutchPackage: order?.orderClutchPlan });
  const packageNameString = `${order?.orderClutchPlan?.name} Package`;

  const renderedProtections = order?.orderProtections
    .filter((protection: OrderProtection) => protection.protectionType !== OrderProtectionType.EXTENDED_WARRANTY)
    .sort((a: OrderProtection, b: OrderProtection) => {
      return a.protectionType === OrderProtectionType.GAP ? 1 : b.protectionType === OrderProtectionType.GAP ? -1 : 0;
    });

  const getPurchaseValueString = () => {
    return `${MoneyMask(isFinancing ? purchaseValueBiWeekly : clutchPlanSubtotal)}${isFinancing ? ' / biweekly' : ''}`;
  };

  return (
    <>
      <Styled.BreakdownHeaderRow expand>
        <Styled.BreakdownHeader>Purchase value</Styled.BreakdownHeader>
        <Styled.BreakdownHeader>{getPurchaseValueString()}</Styled.BreakdownHeader>
      </Styled.BreakdownHeaderRow>

      {!expand && <BreakdownButton text="View full breakdown" toggleState={purchaseValueExpandedState} />}
      <Styled.BreakdownItemsContainer>
        <Collapse in={purchaseValueExpandedState.value || expand}>
          <Styled.BreakdownItemRow>
            <Styled.BreakdownItemText>Vehicle price</Styled.BreakdownItemText>
            <Styled.BreakdownItemText>{MoneyMask(vehiclePrice)}</Styled.BreakdownItemText>
          </Styled.BreakdownItemRow>
          <Styled.BreakdownItemRow>
            <Styled.BreakdownItemText>{packageNameString}</Styled.BreakdownItemText>
            <Styled.BreakdownItemText>
              {clutchPackagePriceWithWarranty ? MoneyMask(clutchPackagePriceWithWarranty) : ''}
            </Styled.BreakdownItemText>
          </Styled.BreakdownItemRow>
          <Styled.BreakdownSubItemContainer>
            {packageBenefitsList.map(({ name, value }: { name: string; value: string }) => (
              <Styled.BreakdownItemRow key={name}>
                <Styled.BreakdownItemText>{name}</Styled.BreakdownItemText>
                <Styled.BreakdownItemText>{value}</Styled.BreakdownItemText>
              </Styled.BreakdownItemRow>
            ))}
          </Styled.BreakdownSubItemContainer>
          {!!renderedProtections &&
            renderedProtections.map(
              ({
                name,
                price,
                protectionTypeName,
                protectionType,
              }: {
                name: string;
                price: number;
                protectionTypeName: string;
                protectionType: string;
              }) => (
                <>
                  <Styled.BreakdownItemRow>
                    <Styled.BreakdownItemText>
                      {protectionType === OrderProtectionType.GAP ? protectionTypeName : name}
                    </Styled.BreakdownItemText>
                    <Styled.BreakdownItemText>{MoneyMask(price)}</Styled.BreakdownItemText>
                  </Styled.BreakdownItemRow>
                  {!!protectionDiscounts && !!protectionDiscounts[name] && (
                    <Styled.BreakdownSubItemContainer>
                      <Styled.BreakdownItemRow>
                        <Styled.BreakdownItemText>{`${order?.orderClutchPlan?.name} Discount`}</Styled.BreakdownItemText>
                        <Styled.BreakdownItemText>-{MoneyMask(protectionDiscounts[name])}</Styled.BreakdownItemText>
                      </Styled.BreakdownItemRow>
                    </Styled.BreakdownSubItemContainer>
                  )}
                </>
              ),
            )}
        </Collapse>
      </Styled.BreakdownItemsContainer>
    </>
  );
};
