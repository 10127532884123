import { Box, Typography } from '@mui/material';
import * as Styled from '../OrderDetails.styles';
import { MoneyMask } from 'src/helpers/masks';
import { useContext } from 'react';
import { CheckoutContext } from 'src/contexts';
import { RetailCheckoutContext } from 'src/contexts/retailCheckout';

export const Deposit = ({ paid = false }: { paid?: boolean }) => {
  const { financeCalculator } = useContext(CheckoutContext);
  const { paymentDetails } = useContext(RetailCheckoutContext);

  const { finalPriceIncFees } = financeCalculator;

  const isVehicleHoldAfterTax = finalPriceIncFees < 0;
  const text = paid ? 'Paid today' : 'Due today';

  const displayCardInformation = () => {
    if (paid && paymentDetails?.card) {
      const cardName = paymentDetails.card?.brand?.charAt(0)?.toUpperCase() + paymentDetails.card?.brand?.slice(1);
      return `${cardName} ending in **** ${paymentDetails.card?.last4}`;
    }
    return 'This fully refundable deposit will reserve your ride and contribute to the final sale price.';
  };

  return (
    <>
      <Styled.TotalRow>
        <Styled.TotalText>
          {text}
          {isVehicleHoldAfterTax && <Styled.TotalTextNote> (deposit)</Styled.TotalTextNote>}
        </Styled.TotalText>
        <Styled.TotalText>{MoneyMask(100)}</Styled.TotalText>
      </Styled.TotalRow>
      <Box mt={1}>
        <Typography variant="caption" lineHeight="18px">
          {displayCardInformation()}
        </Typography>
      </Box>
    </>
  );
};
