import { Box, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';

import { Downpayment, Incentive, PurchaseValue, TaxesAndFees, Total, TradeIn } from 'src/components/OrderDetails';
import { FinanceApplicationContext, VehicleDetailsContext } from 'src/contexts';
import { theme } from 'src/theme';
import type { PrivatePurchaseOffer } from 'src/api/privatePurchase';

export const PurchaseSection = () => {
  const { financeCalculatorHook, order, financeApplication, privatePurchases } = useContext(FinanceApplicationContext);
  const { vehicle } = useContext(VehicleDetailsContext);

  const tradeIn = privatePurchases?.find(
    (privatePurchase: PrivatePurchaseOffer) =>
      privatePurchase.id === financeApplication?.privatePurchaseId || financeApplication?.activeTask?.privatePurchaseId,
  );

  useEffect(() => {
    if (tradeIn?.priceDetails) {
      financeCalculatorHook.setTradeInPrice(tradeIn.priceDetails.vehicleValue + tradeIn.priceDetails.taxSavings);
      financeCalculatorHook.setTradeInCreditTaxSavings(tradeIn.priceDetails.taxSavings);
      financeCalculatorHook.setLienAmount(tradeIn.priceDetails.lienAmount);
    }
  }, [tradeIn]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ borderBottom: `1px solid ${theme.colors.altoGrey}` }}>
        <Stack
          sx={muiTheme => ({
            padding: '30px 32px',
            gap: '8px',
            [muiTheme.breakpoints.down('md')]: {
              padding: '16px',
            },
          })}
        >
          <Typography variant="subtitle2">Order #{order?.referenceCode}</Typography>
          <Typography variant="body2">
            {vehicle?.year} {vehicle?.name}
          </Typography>
        </Stack>
      </Box>

      <Stack
        sx={muiTheme => ({
          padding: '30px 32px',
          [muiTheme.breakpoints.down('md')]: {
            padding: '16px',
          },
        })}
      >
        <PurchaseValue
          order={order}
          purchaseValueBiWeekly={financeCalculatorHook.purchaseValueBiWeekly}
          clutchPlanSubtotal={financeCalculatorHook.clutchPlanSubtotal}
          vehiclePrice={financeCalculatorHook.vehiclePrice}
          clutchPackagePriceWithWarranty={financeCalculatorHook.clutchPackagePriceWithWarranty}
          protectionDiscounts={financeCalculatorHook.protectionDiscounts}
          isFinancing={false} // Passing false to display non-biweekly/monthly price
          expand
        />

        <Incentive incentive={order?.activeReferral?.incentive} finalPriceIncFees={financeCalculatorHook.finalPriceIncFees} />

        <TaxesAndFees
          fees={financeCalculatorHook.fees}
          taxBiWeekly={financeCalculatorHook.taxBiWeekly}
          totalFeesAndTaxesNoTradeIn={financeCalculatorHook.totalFeesAndTaxesNoTradeIn}
          taxRowsNoTradeIn={financeCalculatorHook.taxRowsNoTradeIn}
          isFinancing={false} // Passing false to display non-biweekly/monthly price
          expand
        />

        <TradeIn
          tradeInCreditBiWeekly={financeCalculatorHook.tradeInCreditBiWeekly}
          tradeInPrice={financeCalculatorHook.tradeInPrice}
          vehicleTotalTaxAmountNoTradeIn={financeCalculatorHook.vehicleTotalTaxAmountNoTradeIn}
          lienAmount={financeCalculatorHook.lienAmount}
          privatePurchase={tradeIn}
          isFinancing={false} // Passing false to display non-biweekly/monthly price
          expand
          hideTotalLineItem
        />

        <Downpayment downPayment={financeCalculatorHook.downPayment} />

        <Total
          biWeeklyPayment={financeCalculatorHook.biWeeklyPayment}
          finalPriceIncFees={financeCalculatorHook.finalPriceIncFees}
          loanTermLength={financeCalculatorHook.loanTermLength}
          hideFinancingInfo
          isFinancing={false} // Passing false to display non-biweekly/monthly price
          totalText="Total loan"
        />
      </Stack>
    </Box>
  );
};
