export enum Sections {
  TRADE_IN = 'Trade-In',
  BUYER_INFO = 'Buyer Info',
  CLUTCH_PLAN = 'Packages',
  FINANCE_OR_CASH = 'Finance/Cash',
  GAP_SELECT = 'GAP',
  PROTECTION_PLANS = 'Protection Plans',
  SCHEDULE = 'Schedule',
  DRIVERS_LICENSE = "Driver's License",
  REVIEW = 'Review',
}
