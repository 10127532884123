import * as Sentry from '@sentry/browser';
import type { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { LDMultiKindContext, useLDClient } from 'launchdarkly-react-client-sdk';
import type React from 'react';
import { createContext, useContext, useEffect } from 'react';
import { BotAgentRegex } from 'src/constants';
import { AuthContext } from 'src/contexts/auth';
import { useLocalStorage } from 'src/hooks';
import { LD_SESSION_KEY } from 'src/index';
import { v4 as uuidv4 } from 'uuid';

export type RenderProps = {
  components: Record<string, React.ReactNode>;
  fallback: React.ReactNode;
  experiment: string;
};

type TestingContextType = {
  identifyUser: () => void;
};
export const TestingContext = createContext<TestingContextType>({} as TestingContextType);

type TestingProviderProps = {
  children: React.ReactNode;
};
export const TestingProvider = ({ children }: TestingProviderProps) => {
  const authContext = useContext(AuthContext);
  const isAuthenticated: boolean = authContext?.isAuthenticated;
  const user = authContext?.user;
  const isBotUserAgent = BotAgentRegex.test(navigator.userAgent);
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();
  const ldClient = useLDClient();

  const identifyUser = async () => {
    if (!ldClient) return;

    // get any existing ld context from local storage
    let multiContext = getLocalStorageItem<LDMultiKindContext>({ key: LD_SESSION_KEY });

    // if the session is anonymous, or does not exist, create a new session id
    // set a new session id if it is not present in the session storage
    const session = multiContext?.session as LDSingleKindContext;

    const getKey = () => {
      if (session?.key === 'bot_user') return uuidv4();
      return session?.key || uuidv4();
    };

    // if we are logged in, add user info to session
    const userInfo = isAuthenticated && {
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userId: user?.id,
    };

    multiContext = {
      kind: 'multi',
      session: {
        kind: 'session',
        anonymous: isBotUserAgent,
        name: isAuthenticated ? `${user?.firstName} ${user?.lastName}` : 'No User',
        key: isBotUserAgent ? 'bot_user' : getKey(),
        isAuthenticated,
        ...userInfo,
      },
    };

    // if user is logged into clutch, add their clutch context
    if (!isBotUserAgent && isAuthenticated) {
      multiContext.clutch = {
        kind: 'clutch',
        key: user?.id,
        ...userInfo,
      };
    }

    setLocalStorageItem({ key: LD_SESSION_KEY, value: multiContext });

    // update the current ld identity with this context
    await ldClient.identify(multiContext);
  };

  // Unanonymize the user context
  useEffect(() => {
    // whether we are logging in or out we should update the context
    identifyUser();

    if (isBotUserAgent) Sentry.captureMessage(`Bot detected with User Agent: ${navigator.userAgent}`, Sentry.Severity.Info);
  }, [isAuthenticated, ldClient]);

  return <TestingContext.Provider value={{ identifyUser }}>{children}</TestingContext.Provider>;
};
