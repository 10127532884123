/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { useContext, useEffect, useState } from 'react';
// TODO: combine all these into the same import
import { creditScoreItems, provinceMap } from '@clutch/clutch-common/lib/constants';
import { billOfSale, financing, getMaxLoanLengthForYear } from '@clutch/clutch-common/lib/utils';
import formatTaxNames from '@clutch/clutch-common/lib/utils/bill-of-sale/helpers/format-tax-names';
import { useBooleanState } from '@clutch/hooks';

import dayjs from 'dayjs';
import { useClutchFinancingPackageWithLowestFee } from 'src/api/swr/useClutchFinancingPackageWithLowestFee';
import { AuthContext, LocationContext, VehicleDetailsContext } from '../contexts';
import calculateMaxPreApprovalAmount from '../helpers/calculateMaxPreApprovalAmount';
import getLoanLengthItemsForYear from '../helpers/get-loan-length-items-for-year';
import { formatAncillaryProtectionsForBos } from 'src/helpers/format-protections-for-bos';
import { ProtectionTypeNames } from '../static';
import { ORDER_CONSTANTS } from '../static';

const { calculateFinancePayments, constants: FinancingConstants } = financing;
const { GAP_INSURANCE } = ProtectionTypeNames;

// Note: when making changes to this hook, please test the following flows/
// forms to ensure all calculations and numbers remain consistent:
//
// a) checkout
// b) financing application
// c) financing calculator
const useFinanceCalculator = ({
  tradeInPriceInput = 0,
  tradeInTaxSavings = 0,
  isCheckout = false,
  isFinancing = false,
  interestRate = 0,
  answers = null,
} = {}) => {
  const { vehicle: vehicleDetails } = useContext(VehicleDetailsContext);
  const { user, userSettings } = useContext(AuthContext);
  const locationContext = useContext(LocationContext);
  const [vehicle, setVehicle] = useState(vehicleDetails);
  const [tradeInPrice, setTradeInPrice] = useState(tradeInPriceInput + tradeInTaxSavings);
  const [tradeInCreditTaxSavings, setTradeInCreditTaxSavings] = useState(tradeInTaxSavings);
  const [lienAmount, setLienAmount] = useState(0);
  const [vehicleProtections, setVehicleProtections] = useState(/** @type {any[]} */ ([]));
  const [biWeeklyPayment, setBiWeeklyPayment] = useState();
  const [monthlyPayment, setMonthlyPayment] = useState();
  const [clutchPlan, setClutchPlan] = useState(answers?.orderClutchPlan);
  const isQualifiedState = useBooleanState({
    initialState: !!user.preQualification,
  });
  const isFinancingState = useBooleanState();
  const [deliveryDate, setDeliveryDate] = useState();

  // setting states
  const [aprRate, setAprRate] = useState(interestRate);
  const [downPayment, setDownPayment] = useState(user?.settings?.downPayment || 0);
  const [loanLength, setLoanLength] = useState(0);
  const [suggestedMinDownPayment, setSuggestedMinDownPayment] = useState(0);
  const [vehiclePrice, setVehiclePrice] = useState(vehicleDetails?.price || 0);
  // The order's delivery address should be the source of truth for determining
  // the order's province code (we need this to calculate taxes, among other things).
  // Fallback location: the user's preferred location.
  const location = answers?.deliveryDetails?.location || locationContext?.closestLocation;
  const [provinceCode, setProvinceCode] = useState(R.pathOr('ON', ['region', 'provinceId'], location));
  const [taxBiWeekly, setTaxBiWeekly] = useState(0);
  const [preTaxBiWeekly, setPreTaxBiWeekly] = useState(0);
  const [purchaseValueBiWeekly, setPurchaseValueBiWeekly] = useState(0);
  const [tradeInCreditBiWeekly, setTradeInCreditBiWeekly] = useState(0);
  const [downPaymentBiWeekly, setDownPaymentBiWeekly] = useState(0);
  // Default fees/credits are set here from an order payload (i.e., using this hook in checkout).
  // We don't pass "answers" into this hook from the financing app form,
  // so instead we set fees/credits via the useState setter for bosFees and bosCredits.
  const [bosFees, setBosFees] = useState(answers?.payments?.filter(payment => payment.type === 'FEE'));
  const [bosCredits, setBosCredits] = useState([
    {
      name: 'Online Deposit',
      price: isCheckout ? 100 : 0,
      taxes: [],
    },
    {
      ...(answers?.incentive?.incentiveAmount && {
        name: answers?.incentive?.isReferral ? 'Referral Reward' : `${answers.incentive?.company?.name} Discount`,
        price: answers?.incentive?.incentiveAmount,
        taxes: [],
      }),
    },
    ...(answers?.payments?.filter(payment => payment.type === 'CREDIT') || []),
  ]);
  const { data: clutchFinancingPlanWithLowestFee } = useClutchFinancingPackageWithLowestFee({
    provinceCode,
  });

  const discountAmount = vehicle.amountDiscounted || vehiclePrice * vehicle.percentDiscounted || 0;
  const vehicleSellingPrice = vehiclePrice - discountAmount;
  const vehicleTaxableAmount = vehicleSellingPrice + Number(tradeInPrice);

  const getVehicleNumDaysListed = () => {
    const currentDate = dayjs();
    const firstVisibleDate = dayjs(vehicle.firstVisibleDate);
    return currentDate.diff(firstVisibleDate, 'days');
  };

  /* eslint-disable indent */
  const provinceFees = vehicleSellingPrice
    ? [
        {
          name: 'Shipping',
          price: vehicle.shippingFee,
          taxes: formatTaxNames({
            taxNames: provinceMap[provinceCode].shippingTaxes,
            provinceCode,
          }),
        },
        ...billOfSale.formatProvinceFees({
          provinceCode,
          vehiclePrice: vehicleSellingPrice,
          loanTermLength: loanLength,
          orderType: user?.isDealer ? ORDER_CONSTANTS.DEAL_TYPE.DEALER : ORDER_CONSTANTS.DEAL_TYPE.RETAIL,
          dealType: isFinancingState.value || isFinancing ? 'FINANCE' : 'CASH',
          daysListed: getVehicleNumDaysListed(),
          dateOfBirth: user?.dateOfBirth,
          familyNameOrCompanyName: user?.lastName,
          deliveryDate,
        }),
      ]
    : [];

  const bosParams = {
    vehiclePrice: vehicleSellingPrice,
    downPayment,
    provinceCode,
    dealType: isFinancingState.value || isFinancing ? 'FINANCE' : 'CASH',
    privatePurchases: [{ price: tradeInPrice - tradeInCreditTaxSavings, lienAmount, taxSavings: tradeInCreditTaxSavings }], // BOS util expects trade-in price separate from tax savings amount
    protections: formatAncillaryProtectionsForBos({ ancillaries: vehicleProtections, provinceCode }),
    credits: bosCredits,
    fees: bosFees || provinceFees,
    vehicleFuelType: R.path(['fuelType', 'name'], vehicleDetails),
    vehicleMileage: R.path(['mileage'], vehicleDetails),
    clutchPlan: clutchPlan || (answers && Object.keys(answers).length > 0 ? answers.orderClutchPlan : clutchFinancingPlanWithLowestFee),
  };

  const getBillOfSaleValues = (backendProducts = []) => {
    if (vehicleSellingPrice) {
      try {
        // allow for overriding the default vehicleProtections
        // present in the bosParams payload
        const bosArgs =
          backendProducts.length > 0
            ? { ...bosParams, protections: formatAncillaryProtectionsForBos({ ancillaries: backendProducts, provinceCode }) }
            : bosParams;

        return billOfSale.generateBillOfSale(bosArgs);
      } catch (error) {
        Sentry.captureMessage('Unable to calculate bill of sale');
        Sentry.captureException(error);
      }
    }
    return {
      taxableSubtotal: 0,
      taxes: [],
      totalTaxAmount: 0,
      fees: {},
    };
  };

  const vehicleProtectionsWithoutGap = vehicleProtections.filter(plan => plan.protectionTypeName !== GAP_INSURANCE);
  const { financeAmount: totalLoanAmountBeforeGAP } = getBillOfSaleValues(vehicleProtectionsWithoutGap);

  const billOfSaleValues = getBillOfSaleValues();

  const { taxes, taxRowsNoTradeIn, totalFees, financeAmount, totalPrice, totalTaxAmount, totalFeesAndTaxesNoTradeIn } = billOfSaleValues;

  const finalTotal = financeAmount ? financeAmount + 100 : totalPrice + 100 || 0;
  const maxPrivatePurchaseValue = Math.round(billOfSaleValues.maxPrivatePurchaseValue);
  const maxDownPayment = Math.round(billOfSaleValues.maxDownPayment);
  const loanTerms = getLoanLengthItemsForYear({ year: vehicle.year });
  const financeAmountWithoutDownPayment = billOfSaleValues.financeAmount + billOfSaleValues.downPayment;
  const totalFeesAndTaxes = totalTaxAmount + totalFees;

  const { monthly: monthlyMaxPayment } = calculateFinancePayments({
    apr: user?.preQualification?.financingDecision?.interestRate || 0,
    months: user?.preQualification?.financingDecision?.termInMonths || 0,
    totalLoanAmount: user?.preQualification?.financingDecision?.netAmountFinanced || 0,
  });
  const maxPreApprovedAmount = calculateMaxPreApprovalAmount({
    monthlyPayment: monthlyMaxPayment,
    interestRate,
    loanLength,
  });

  const setNewSuggestedMinDownPayment = () => {
    if (
      !user?.settings?.downPayment &&
      answers?.creditCheckDetails?.annualIncome &&
      interestRate &&
      !!maxPreApprovedAmount &&
      maxPreApprovedAmount < financeAmountWithoutDownPayment
    ) {
      const newMinDownPayment = Math.ceil(financeAmountWithoutDownPayment - maxPreApprovedAmount);
      if (newMinDownPayment > 0) {
        const newDownPayment = newMinDownPayment > maxDownPayment ? maxDownPayment : newMinDownPayment;
        setSuggestedMinDownPayment(newDownPayment);
        if (!downPayment) {
          setDownPayment(newDownPayment);
        }
      }
    } else if (maxPreApprovedAmount >= financeAmountWithoutDownPayment) {
      setSuggestedMinDownPayment(0);
    }
  };

  const removeGAPFromProtections = () => {
    const newProtections = vehicleProtections.filter(plan => plan.protectionTypeName !== GAP_INSURANCE);
    setVehicleProtections(newProtections);
  };

  const addGAPToProtections = gapProtection => {
    const newProtections = vehicleProtections;
    newProtections.push(gapProtection);
    setVehicleProtections(newProtections);
  };

  // Trade-in credit tax savings is either the STC's tax savings amount, or the
  // vehicleTotalTaxAmountNoTradeIn amount -- whichever is lower.
  // This allows us to handle cases where the trade-in amount is greater than the
  // cost of the vehicle being purchased.
  const calculateTradeInTaxSavings = ({ privatePurchaseTaxSavings }) => {
    return billOfSaleValues?.vehicleTotalTaxAmountNoTradeIn
      ? Math.min(privatePurchaseTaxSavings, billOfSaleValues.vehicleTotalTaxAmountNoTradeIn)
      : privatePurchaseTaxSavings;
  };

  useEffect(() => {
    if (user?.preQualification?.financingDecision?.interestRate) {
      isQualifiedState.setTrue();
    } else {
      isQualifiedState.setFalse();
    }
  }, [user?.preQualification?.financingDecision?.interestRate]);

  // Ensure local vehicle state is up to date with vehicleDetailsContext
  useEffect(() => {
    setVehicle(vehicleDetails);
    setVehiclePrice(vehicleDetails?.price || 0);
  }, [vehicleDetails.id, vehicleDetails.price, vehicleDetails.discount, vehicleDetails.shippingFee]);

  // On load set the and apr rate and loan length based on vehicle when not in
  // checkout flow
  useEffect(() => {
    if (!isCheckout) {
      const maxLoanLength = getMaxLoanLengthForYear(vehicle.year);
      setLoanLength(maxLoanLength);
      // sync user settings
      if (!userSettings.loanLength || userSettings.loanLength !== maxLoanLength) {
        userSettings.update({ loanLength: maxLoanLength });
      }

      const lowestAprByYear = R.last(creditScoreItems).value;
      const lowestDefaultApr = FinancingConstants.DEFAULT_VALUES.APR;
      lowestAprByYear > lowestDefaultApr ? setAprRate(lowestAprByYear) : setAprRate(lowestDefaultApr);
    }
  }, [vehicle.id]);

  useEffect(() => {
    // when the tradeIn value is greater than the vehiclePrice, finalTotal is negative
    if (finalTotal > 0) {
      const { monthly, biWeekly } = calculateFinancePayments({
        apr: aprRate,
        months: loanLength,
        totalLoanAmount: finalTotal,
      });
      // used for clutch plan order summary renders
      const { biWeekly: taxBiWeekly } = calculateFinancePayments({
        apr: aprRate,
        months: loanLength,
        totalLoanAmount: totalFeesAndTaxesNoTradeIn || 0,
      });
      const { biWeekly: preTaxBiWeekly } = calculateFinancePayments({
        apr: aprRate,
        months: loanLength,
        totalLoanAmount: billOfSaleValues.preTaxTotal || 0,
      });
      const { biWeekly: purchaseValueBiWeekly } = calculateFinancePayments({
        apr: aprRate,
        months: loanLength,
        totalLoanAmount: billOfSaleValues.clutchPlanSubtotal,
      });
      const { biWeekly: tradeInBiWeekly } = calculateFinancePayments({
        apr: aprRate,
        months: loanLength,
        totalLoanAmount: Math.abs(billOfSaleValues.tradeInCredit || 0),
      });
      const { biWeekly: downPaymentBiWeekly } = calculateFinancePayments({
        apr: aprRate,
        months: loanLength,
        totalLoanAmount: downPayment || 0,
      });

      setBiWeeklyPayment(Math.round(biWeekly));
      setMonthlyPayment(Math.round(monthly));
      setTaxBiWeekly(Math.round(taxBiWeekly));
      setPreTaxBiWeekly(Math.round(preTaxBiWeekly));
      setPurchaseValueBiWeekly(Math.round(purchaseValueBiWeekly));
      setDownPaymentBiWeekly(Math.round(downPaymentBiWeekly));
      setTradeInCreditBiWeekly(billOfSaleValues.tradeInCredit > 0 ? Math.round(tradeInBiWeekly) : -Math.round(tradeInBiWeekly));
    }
  }, [
    loanLength,
    aprRate,
    finalTotal,
    downPayment,
    totalFeesAndTaxes,
    billOfSaleValues.clutchPlanSubtotal,
    billOfSaleValues.clutchPlanSubtotalWithTradeIn,
    billOfSaleValues.tradeInCredit,
  ]);

  useEffect(() => {
    if (interestRate) {
      setAprRate(interestRate);
    }
  }, [interestRate]);

  useEffect(() => {
    if (answers?.orderClutchPlan && !answers.orderClutchPlan?.isTradeInAvailable) {
      setTradeInPrice(0);
      setTradeInCreditTaxSavings(0);
      setLienAmount(0);
    } else if (tradeInPriceInput > 0) {
      const taxSavings = calculateTradeInTaxSavings({ privatePurchaseTaxSavings: tradeInTaxSavings });
      setTradeInCreditTaxSavings(taxSavings);
      setTradeInPrice(tradeInPriceInput + taxSavings);
    }
  }, [tradeInPriceInput, tradeInTaxSavings, billOfSaleValues?.vehicleTotalTaxAmountNoTradeIn, answers?.orderClutchPlan]);

  // set a minimum downpayment if the pre-approved amount is less than the total cost of the vehicle
  useEffect(() => {
    setNewSuggestedMinDownPayment();
  }, [answers?.creditCheckDetails?.annualIncome, interestRate, financeAmountWithoutDownPayment, loanLength]);

  useEffect(() => {
    setProvinceCode(R.pathOr('ON', ['region', 'provinceId'], location));
  }, [JSON.stringify(location)]);

  return {
    setVehicle,
    isVehicleLoading: !vehicle || R.isEmpty(vehicle),
    tradeInPrice,
    setTradeInPrice,
    downPayment,
    setDownPayment,
    loanLength,
    setLoanLength,
    aprRate,
    setAprRate,
    isMonthly: userSettings.isMonthly,
    setPaymentInterval: paymentInterval => userSettings.update({ paymentInterval }),
    togglePaymentInterval: userSettings.togglePaymentInterval,
    lienAmount,
    setLienAmount,
    setVehicleProtections,
    biWeeklyPayment,
    monthlyPayment,
    totalTaxAmount,
    taxes,
    fees: billOfSaleValues.fees,
    credits: billOfSaleValues.credits || [], // this should be done inside common
    maxPrivatePurchaseValue,
    maxDownPayment,
    loanTerms,
    vehicleSellingPrice,
    finalPriceIncFees: finalTotal,
    vehicleTaxableAmount,
    totalFees,
    isQualifiedState,
    isFinancingState,
    totalLoanAmount: finalTotal,
    totalLoanAmountBeforeGAP,
    taxableSubtotal: billOfSaleValues.taxableSubtotal,
    removeGAPFromProtections,
    addGAPToProtections,
    setDeliveryDate,
    shippingFee: billOfSaleValues.fees['Shipping Fee'] || 0,
    suggestedMinDownPayment,
    setNewSuggestedMinDownPayment,
    setVehiclePrice,
    setProvinceCode,
    vehiclePrice,
    clutchPlanSubtotal: billOfSaleValues.clutchPlanSubtotal,
    setClutchPlan,
    clutchPlanSubtotalWithTradeIn: billOfSaleValues.clutchPlanSubtotalWithTradeIn,
    tradeInCredit: billOfSaleValues.tradeInCredit,
    totalFeesAndTaxes,
    totalFeesAndTaxesNoTradeIn,
    taxBiWeekly,
    preTaxBiWeekly,
    purchaseValueBiWeekly,
    tradeInCreditBiWeekly,
    downPaymentBiWeekly,
    taxRowsNoTradeIn: billOfSaleValues.taxRowsNoTradeIn,
    vehicleTotalTaxAmountNoTradeIn: billOfSaleValues.vehicleTotalTaxAmountNoTradeIn,
    tradeInCreditTaxSavings,
    setTradeInCreditTaxSavings,
    clutchPackagePriceWithWarranty: billOfSaleValues.clutchPackagePriceWithWarranty,
    protectionDiscounts: billOfSaleValues.protectionDiscounts,
    calculateTradeInTaxSavings,
    bosParams,
    setBosFees,
    setBosCredits,
  };
};

export default useFinanceCalculator;
