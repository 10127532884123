import type { UseBooleanState } from '@clutch/hooks';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as Styled from '../OrderDetails.styles';

export const BreakdownButton = ({ text, toggleState }: { text: string; toggleState: UseBooleanState }) => (
  <Styled.BreakdownButton onClick={toggleState.toggle}>
    {text} {toggleState.value ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
  </Styled.BreakdownButton>
);
