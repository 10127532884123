import type { CityData } from './citySTC.types';

export const CITY_FILE_URLS = {
  TORONTO: '/data/city-stc/cars-bought-in-city/toronto.json',
  LONDON: '/data/city-stc/cars-bought-in-city/london.json',
  OTTAWA: '/data/city-stc/cars-bought-in-city/ottawa.json',
  KITCHENER: '/data/city-stc/cars-bought-in-city/kitchener.json',
  BARRIE: '/data/city-stc/cars-bought-in-city/barrie.json',
  HAMILTON: '/data/city-stc/cars-bought-in-city/hamilton.json',
  OSHAWA: '/data/city-stc/cars-bought-in-city/oshawa.json',
  NEWMARKET: '/data/city-stc/cars-bought-in-city/newmarket.json',
  HALIFAX: '/data/city-stc/cars-bought-in-city/halifax.json',
  MONCTON: '/data/city-stc/cars-bought-in-city/moncton.json',
};

export const CITY_DETAILS: Record<string, CityData> = {
  TORONTO: {
    name: 'Toronto',
    province: 'ON',
    butterId: 'stc-toronto',
    offersFileUrl: CITY_FILE_URLS.TORONTO,
    highlightInFooter: true,
  },
  BRAMPTON: {
    name: 'Brampton',
    province: 'ON',
    butterId: 'stc-brampton',
    offersFileUrl: CITY_FILE_URLS.TORONTO,
  },
  MARKHAM: {
    name: 'Markham',
    province: 'ON',
    butterId: 'stc-markham',
    offersFileUrl: CITY_FILE_URLS.TORONTO,
  },
  MISSISSAUGA: {
    name: 'Mississauga',
    province: 'ON',
    butterId: 'stc-mississauga',
    offersFileUrl: CITY_FILE_URLS.TORONTO,
    highlightInFooter: true,
  },
  LONDON: {
    name: 'London',
    province: 'ON',
    butterId: 'stc-london',
    offersFileUrl: CITY_FILE_URLS.LONDON,
    highlightInFooter: true,
  },
  OTTAWA: {
    name: 'Ottawa',
    province: 'ON',
    butterId: 'stc-ottawa',
    offersFileUrl: CITY_FILE_URLS.OTTAWA,
    highlightInFooter: true,
  },
  GUELPH: {
    name: 'Guelph',
    province: 'ON',
    butterId: 'stc-guelph',
    offersFileUrl: CITY_FILE_URLS.KITCHENER,
  },
  KITCHENER: {
    name: 'Kitchener',
    province: 'ON',
    butterId: 'stc-kitchener',
    offersFileUrl: CITY_FILE_URLS.KITCHENER,
    highlightInFooter: true,
  },
  BARRIE: {
    name: 'Barrie',
    province: 'ON',
    butterId: 'stc-barrie',
    offersFileUrl: CITY_FILE_URLS.BARRIE,
  },
  ST_CATHARINES: {
    name: 'St. Catharines',
    province: 'ON',
    butterId: 'stc-st-catharines',
    offersFileUrl: CITY_FILE_URLS.HAMILTON,
  },
  HAMILTON: {
    name: 'Hamilton',
    province: 'ON',
    butterId: 'stc-hamilton',
    offersFileUrl: CITY_FILE_URLS.HAMILTON,
    highlightInFooter: true,
  },
  OSHAWA: {
    name: 'Oshawa',
    province: 'ON',
    butterId: 'stc-oshawa',
    offersFileUrl: CITY_FILE_URLS.OSHAWA,
  },
  NEWMARKET: {
    name: 'Newmarket',
    province: 'ON',
    butterId: 'stc-newmarket',
    offersFileUrl: CITY_FILE_URLS.NEWMARKET,
  },
  HALIFAX: {
    name: 'Halifax',
    province: 'NS',
    butterId: 'stc-halifax',
    offersFileUrl: CITY_FILE_URLS.HALIFAX,
    highlightInFooter: true,
  },
  CAPE_BRETON: {
    name: 'Cape Breton',
    province: 'NS',
    butterId: 'stc-cape-breton',
    offersFileUrl: CITY_FILE_URLS.HALIFAX,
  },
  MONCTON: {
    name: 'Moncton',
    province: 'NB',
    butterId: 'stc-moncton',
    offersFileUrl: CITY_FILE_URLS.MONCTON,
  },
};

export const getReviewSchemaScript = (city: string) => ({
  '@context': 'https://schema.org',
  '@type': 'Product',
  name: `Sell My Car in ${city} | Clutch`,
  url: `https://www.clutch.ca/sell-my-car-${city.toLowerCase()}`,
  datePublished: '2023-08-22',
  dateModified: '2024-09-03',
  brand: { '@type': 'Brand', name: `Sell My Car in ${city} | Clutch` },
  description: `Sell your car online quickly and easily in ${city}. Enter your licence plate or VIN to get a real, custom offer. Good for 7 days!`,
  image: ['https://cdn.prod.website-files.com/60801cda8fa75de6c063cabf/60e749996f640e85c558c3cc_clutch-logo.svg'],
  mpn: 'N/A',
  sku: 'N/A',
  aggregateRating: { '@type': 'AggregateRating', ratingValue: 4.7, reviewCount: 3213, bestRating: 5, worstRating: 1 },
  review: {
    '@type': 'Review',
    reviewBody:
      'Adam made the inspection super easy and the clutch team made everything seamless, got exactly what was appraised and in and out in the same day.',
    datePublished: '2024-07-23',
    author: { '@type': 'Person', name: 'Sreyas D.' },
    reviewRating: { '@type': 'Rating', ratingValue: 5 },
  },
});
