import { useBooleanState } from '@clutch/hooks';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import { useContext } from 'react';

import { LocationContext } from 'src/contexts';
import { DebtTypes } from 'src/contexts/privatePurchaseShell/constants';
import { MoneyMask } from 'src/helpers/masks';
import { usePrivatePurchaseOfferBreakdown } from 'src/hooks';
import { theme } from 'src/theme';

import { BreakdownButton } from '../BreakdownButton';
import * as Styled from '../OrderDetails.styles';

type TradeInProps = {
  tradeInCreditBiWeekly: number;
  tradeInPrice: number;
  vehicleTotalTaxAmountNoTradeIn: number;
  lienAmount: number;
  privatePurchase: any;
  isFinancing: boolean;
  expand?: boolean;
  hideTotalLineItem?: boolean;
};

export const TradeIn = ({
  tradeInCreditBiWeekly,
  tradeInPrice,
  vehicleTotalTaxAmountNoTradeIn,
  lienAmount,
  privatePurchase,
  isFinancing,
  expand = false,
  hideTotalLineItem = false,
}: TradeInProps) => {
  const tradeInCreditExpandedState = useBooleanState();

  const locationContext = useContext(LocationContext);
  const location = locationContext?.locations?.locations.find(loc => loc.id === locationContext?.preferredLocation.closestLocationId);

  const leaseBuyoutFee = location?.leaseBuyoutFee || 0;

  const offerBreakdown = usePrivatePurchaseOfferBreakdown({
    offer: privatePurchase,
    leaseBuyoutFee: leaseBuyoutFee,
    taxSavings: Math.min(privatePurchase?.priceDetails?.taxSavings, vehicleTotalTaxAmountNoTradeIn),
  });

  const netTradeIn = offerBreakdown?.inflatedMarketValue - offerBreakdown?.serviceFee - lienAmount;

  if (!netTradeIn || !tradeInPrice) return null;

  return (
    <Box
      padding={1}
      marginBottom={3}
      borderRadius="6px"
      bgcolor={theme.colors.lightSkyBlue}
      border={`1px solid ${theme.colors.teal}`}
      textAlign="left"
    >
      <Stack direction="row" justifyContent="space-between">
        <Styled.BreakdownHeader>{netTradeIn > 0 ? 'Trade-in credit' : 'Trade-in balance'}</Styled.BreakdownHeader>
        <Styled.BreakdownHeader>
          {netTradeIn > 0 && '-'}
          {isFinancing ? MoneyMask(Math.abs(tradeInCreditBiWeekly)) + ' / biweekly' : MoneyMask(Math.abs(netTradeIn))}
        </Styled.BreakdownHeader>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        {!expand && <BreakdownButton text="View full breakdown" toggleState={tradeInCreditExpandedState} />}
        {!(tradeInCreditExpandedState.value || expand) && <Styled.BreakdownItemText>(incl. tax savings)</Styled.BreakdownItemText>}
      </Stack>

      <Stack>
        <Collapse in={tradeInCreditExpandedState.value || expand}>
          <Styled.BreakdownItemRow>
            <Styled.BreakdownItemText>Market value (incl. tax savings)</Styled.BreakdownItemText>
            <Styled.BreakdownItemText>{MoneyMask(offerBreakdown?.inflatedMarketValue)}</Styled.BreakdownItemText>
          </Styled.BreakdownItemRow>
          <Styled.BreakdownItemRow>
            <Styled.BreakdownItemText>Clutch service fee</Styled.BreakdownItemText>
            <Styled.BreakdownItemText>- {MoneyMask(offerBreakdown?.serviceFee)}</Styled.BreakdownItemText>
          </Styled.BreakdownItemRow>
          {!!lienAmount && (
            <Styled.BreakdownItemRow>
              <Styled.BreakdownItemText>
                Remaining balance on {privatePurchase?.debtType === DebtTypes.LEASED ? 'lease' : 'lien'}
              </Styled.BreakdownItemText>
              <Styled.BreakdownItemText>- {MoneyMask(lienAmount)}</Styled.BreakdownItemText>
            </Styled.BreakdownItemRow>
          )}

          {!hideTotalLineItem && (
            <Styled.BreakdownItemRow>
              <Typography variant="caption" fontWeight={500} color={theme.colors.blackTitle}>
                {netTradeIn > 0 ? 'Trade-in credit' : 'Trade-in balance'}
              </Typography>
              <Typography variant="caption" fontWeight={500} color={theme.colors.blackTitle}>
                {MoneyMask(Math.abs(netTradeIn))}
              </Typography>
            </Styled.BreakdownItemRow>
          )}
        </Collapse>
      </Stack>
    </Box>
  );
};
