import { useBooleanState } from '@clutch/hooks';
import { Collapse } from '@mui/material';

import { MoneyMask } from 'src/helpers/masks';
import { Tax } from 'src/types/api';

import { BreakdownButton } from '../BreakdownButton';
import * as Styled from '../OrderDetails.styles';

type TaxesAndFeesProps = {
  fees: any;
  taxBiWeekly: number;
  totalFeesAndTaxesNoTradeIn: number;
  taxRowsNoTradeIn: any;
  isFinancing: boolean;
  expand?: boolean;
};

export const TaxesAndFees = ({
  fees,
  taxBiWeekly,
  totalFeesAndTaxesNoTradeIn,
  taxRowsNoTradeIn,
  isFinancing,
  expand = false,
}: TaxesAndFeesProps) => {
  const taxesAndFeesExpandedState = useBooleanState();

  const taxesAndFeesString = `${MoneyMask(isFinancing ? taxBiWeekly : totalFeesAndTaxesNoTradeIn)}${isFinancing ? ' / biweekly' : ''}`;

  const formatFeeName = (feeName: string) => {
    const estimatedFees = ['OMVIC', 'Licensing'];
    return estimatedFees.includes(feeName) ? `${feeName} (estimated)` : feeName;
  };

  return (
    <>
      <Styled.BreakdownHeaderRow expand={expand}>
        <Styled.BreakdownHeader>Taxes and fees</Styled.BreakdownHeader>
        <Styled.BreakdownHeader>{taxesAndFeesString}</Styled.BreakdownHeader>
      </Styled.BreakdownHeaderRow>
      {!expand && <BreakdownButton text="View full breakdown" toggleState={taxesAndFeesExpandedState} />}
      <Styled.BreakdownItemsContainer>
        <Collapse in={taxesAndFeesExpandedState.value || expand}>
          {!!fees &&
            Object.entries(fees).map(
              ([feeName, feeAmount]) =>
                !!feeAmount && (
                  <Styled.BreakdownItemRow key={feeName}>
                    <Styled.BreakdownItemText>{formatFeeName(feeName)}</Styled.BreakdownItemText>
                    <Styled.BreakdownItemText>{MoneyMask(feeAmount)}</Styled.BreakdownItemText>
                  </Styled.BreakdownItemRow>
                ),
            )}
          {taxRowsNoTradeIn?.map((tax: Tax) => (
            <Styled.BreakdownItemRow key={tax.name}>
              <Styled.BreakdownItemText>{tax.name}</Styled.BreakdownItemText>
              <Styled.BreakdownItemText>{MoneyMask(tax.amount)}</Styled.BreakdownItemText>
            </Styled.BreakdownItemRow>
          ))}
        </Collapse>
      </Styled.BreakdownItemsContainer>
    </>
  );
};
