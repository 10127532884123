import { formatPhoneNumber } from '@clutch/helpers';
import { Box, Collapse, Link, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { SectionContainer, SubSectionContainer } from 'src/components/molecules/atoms/Containers';
import { getStaticPhotoUrl } from 'src/config';
import { VehicleLookupsContext } from 'src/contexts';
import { useHubspotConversation } from 'src/hooks';
import { ROUTES } from 'src/static';
import { theme } from 'src/theme';

import { emailSupport } from '../../helpers/contact-info';
import { footerCopyrightText, footerNavigationMap } from '../../helpers/navigation';
import { defaultPhoneNumber } from '../../static/defaultPhoneNumber';
import { normalizeCityName } from '../PrivatePurchaseForm/steps/VehicleSearch/Landing/citySTC/citySTC';
import { sellMyCarFooterSection } from '../PrivatePurchaseForm/steps/VehicleSearch/Landing/citySTC/citySTCFooter';
import { SocialMedia } from '../SocialMedia';
import { POPULAR_LOCATIONS, POPULAR_MODELS } from './Footer.constants';

const Footer = () => {
  const vehicleLookupsContext = useContext(VehicleLookupsContext);

  const { openHubspotChatbot } = useHubspotConversation();

  const models = vehicleLookupsContext?.models;
  const bodyStyles = vehicleLookupsContext?.bodyStyles;
  const MAX_FOOTER_ITEMS = 10;

  const navigationKeys = Object.keys(footerNavigationMap) as (keyof typeof footerNavigationMap)[];

  const [showAllSellMyCar, setShowAllSellMyCar] = useState(false);
  const { topItems: topSellMyCarItems, remainingItems: remainingSellMyCarItems } = sellMyCarFooterSection;

  const [showAllLocations, setShowAllLocations] = useState(false);

  return (
    <SectionContainer sx={{ background: theme.colors.offWhite }} padBottom>
      <SubSectionContainer
        sx={muiTheme => ({
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
          maxWidth: '1080px',

          [muiTheme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            rowGap: '56px',
          },
        })}
        disableGutters
      >
        <Stack spacing={2} sx={{ width: '215px' }}>
          <Typography variant="body1" component="h2">
            <span className="bold">Browse Popular Models</span>
          </Typography>
          {POPULAR_MODELS.filter(
            ({ make, model }) =>
              models?.find(m => m.makeName.toLowerCase() === make.toLowerCase() && m.name.toLowerCase() === model.toLowerCase()),
          )
            .map(({ make, model }) => ({
              name: `${make} ${model}`,
              model: { makeName: make, name: model },
            }))
            .map((data, idx) => (
              <Link component={RouterLink} key={idx} to={'/cars/' + data.name.replace(/\s/g, '-').toLowerCase()}>
                <Typography variant="body1">{data.name}</Typography>
              </Link>
            ))}
        </Stack>
        <Stack spacing={2} sx={{ width: '215px' }}>
          <Typography variant="body1" component="h2">
            <span className="bold">Browse by Body Style</span>
          </Typography>
          {bodyStyles
            ?.map((bodyStyle, idx) => (
              <Link key={idx} component={RouterLink} to={'/cars/' + bodyStyle.name.toLocaleLowerCase()}>
                <Typography variant="body1">{bodyStyle.name}</Typography>
              </Link>
            ))
            ?.splice(0, MAX_FOOTER_ITEMS)}
        </Stack>
        <Stack spacing={2} sx={{ width: '215px' }}>
          <Typography variant="body1" component="h2">
            <span className="bold">Browse by Location</span>
          </Typography>
          {POPULAR_LOCATIONS.slice(0, MAX_FOOTER_ITEMS).map((location, idx) => (
            <Link key={idx} component={RouterLink} to={`/cars/in-${normalizeCityName(location.city)}`}>
              <Typography variant="body1">
                Used Cars in {location.city}, {location.provinceCode}
              </Typography>
            </Link>
          ))}
          <Collapse in={showAllLocations} timeout="auto">
            <Stack spacing={2}>
              {POPULAR_LOCATIONS.slice(MAX_FOOTER_ITEMS).map((location, idx) => (
                <Link key={idx} component={RouterLink} to={`/cars/in-${normalizeCityName(location.city)}`}>
                  <Typography variant="body1">
                    Used Cars in {location.city}, {location.provinceCode}
                  </Typography>
                </Link>
              ))}
            </Stack>
          </Collapse>
          {POPULAR_LOCATIONS.length > MAX_FOOTER_ITEMS && (
            <Link
              component="button"
              onClick={() => setShowAllLocations(!showAllLocations)}
              sx={{
                textAlign: 'left',
                textDecoration: 'underline',
                marginTop: showAllLocations ? 'unset' : '0px !important',
              }}
            >
              <Typography variant="body1">{showAllLocations ? 'View Less' : 'View More'}</Typography>
            </Link>
          )}
        </Stack>

        <Stack spacing={2} sx={{ width: '215px' }}>
          <Typography variant="body1" component="h2">
            <span className="bold">Sell My Car</span>
          </Typography>
          <Stack spacing={2}>
            {topSellMyCarItems.map((link, idx) => (
              <Link key={idx} component={RouterLink} to={link.to.pathname}>
                <Typography variant="body1">{link.title}</Typography>
              </Link>
            ))}
          </Stack>
          <Collapse in={showAllSellMyCar} timeout="auto">
            <Stack spacing={2}>
              {remainingSellMyCarItems.map((link, idx) => (
                <Link key={idx} component={RouterLink} to={link.to.pathname}>
                  <Typography variant="body1">{link.title}</Typography>
                </Link>
              ))}
            </Stack>
          </Collapse>
          {remainingSellMyCarItems.length > 0 && (
            <Link
              component="button"
              onClick={() => setShowAllSellMyCar(!showAllSellMyCar)}
              sx={{
                textAlign: 'left',
                textDecoration: 'underline',
                marginTop: showAllSellMyCar ? 'unset' : '0px !important',
              }}
            >
              <Typography variant="body1">{showAllSellMyCar ? 'View Less' : 'View More'}</Typography>
            </Link>
          )}
        </Stack>
      </SubSectionContainer>
      <SubSectionContainer
        sx={muiTheme => ({
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
          maxWidth: '1080px',

          [muiTheme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            rowGap: '56px',
          },
        })}
        disableGutters
      >
        {navigationKeys.map(key => {
          const section = footerNavigationMap[key];
          return (
            <Stack key={key} spacing={2} sx={{ width: '215px' }}>
              <Typography variant="body1" component="h2">
                <span className="bold">{section.title}</span>
              </Typography>
              {section.data.map((link, idx) => {
                if (link.isExternal) {
                  return (
                    <Link key={idx} href={link.to.pathname}>
                      <Typography variant="body1">{link.title}</Typography>
                    </Link>
                  );
                }

                return (
                  <Link key={idx} component={RouterLink} to={link.to}>
                    <Typography variant="body1">{link.title}</Typography>
                  </Link>
                );
              })}
            </Stack>
          );
        })}

        {/* Stack for contact */}
        <Stack spacing={2} sx={{ width: '215px' }}>
          <Typography variant="body1" component="h2">
            <span className="bold">Contact Us</span>
          </Typography>
          <Link onClick={openHubspotChatbot}>
            <Typography variant="body1">
              <span style={{ textDecoration: 'underline' }}>Chat with us</span>
            </Typography>
          </Link>

          <Typography variant="body1">
            Call us at <span style={{ color: theme.colors.blackTitle }}>{formatPhoneNumber(defaultPhoneNumber)}</span>
          </Typography>
          <Typography variant="body1">
            Email us at <span style={{ color: theme.colors.blackTitle }}>{emailSupport}</span>
          </Typography>

          <Link component={RouterLink} to="/contact#locations">
            <Typography variant="body1">Locations</Typography>
          </Link>

          <Box // more than sm only
            sx={muiTheme => ({
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '24px',

              [muiTheme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}
          >
            {/* Social Media */}
            {SocialMedia.map((social, idx) => (
              <Box key={idx}>
                <Link href={social.href} target="_blank" rel="noopener">
                  {social.component()}
                </Link>
              </Box>
            ))}
          </Box>
        </Stack>
      </SubSectionContainer>
      <SubSectionContainer>
        <Box
          sx={muiTheme => ({
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '40px',

            flexWrap: 'wrap',

            [muiTheme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              rowGap: '24px',
            },
          })}
        >
          <Box // mobile only
            sx={muiTheme => ({
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '24px',

              [muiTheme.breakpoints.up('sm')]: {
                display: 'none',
              },
            })}
          >
            {/* Social Media */}
            {SocialMedia.map((social, idx) => (
              <Box key={idx}>
                {/* For security reasons, set rel="noopener" when the target is blank */}
                <Link href={social.href} target="_blank" rel="noopener">
                  {social.component()}
                </Link>
              </Box>
            ))}
          </Box>
          <img src={getStaticPhotoUrl('2024-autotrader-award.png')} alt="auto-trader" width="auto" height={121} />
          <img src={getStaticPhotoUrl('2024-carguru-award.png')} alt="auto-trader" width="auto" height={80} />
          <Stack>
            <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '18px' }}>
              {footerCopyrightText}
            </Typography>
            <Box>
              <Link href={ROUTES.TERMS_OF_SERVICE} sx={{ display: 'inline-block' }}>
                <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '18px' }}>
                  Terms of Service
                </Typography>
              </Link>
              <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '18px', display: 'inline-block' }}>
                &nbsp;|&nbsp;
              </Typography>
              <Link href={ROUTES.PRIVACY_POLICY} sx={{ display: 'inline-block' }}>
                <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '18px' }}>
                  Privacy Policy
                </Typography>
              </Link>
            </Box>
          </Stack>
        </Box>
      </SubSectionContainer>
    </SectionContainer>
  );
};

export default Footer;
