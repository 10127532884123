import { Box, Chip, Stack, Typography } from '@mui/material';
import { useContext } from 'react';

import { Modals } from 'src/constants';
import { FinanceApplicationContext } from 'src/contexts';
import { defaultInterestRate } from 'src/containers/Checkout/RetailCheckout/steps/ClutchPlan/components/PlanPrice/PlanPrice';
import { formatDollars } from 'src/helpers';
import { useModal } from 'src/stores';
import { theme } from 'src/theme';

import { FORM_STEPS } from '../../../../../../contexts/financeApplication/utils';

export const LoanSection = () => {
  const { financeCalculatorHook, financeApplication } = useContext(FinanceApplicationContext);
  const modal = useModal();

  const interestRate =
    financeApplication?.activeTask?.order?.creditCheckDetails?.estimatedInterestRate ||
    financeCalculatorHook?.aprRate ||
    defaultInterestRate;

  const loanPayment = financeCalculatorHook?.isMonthly
    ? `${formatDollars(financeCalculatorHook?.monthlyPayment)}/monthly`
    : `${formatDollars(financeCalculatorHook?.biWeeklyPayment)}/biweekly`;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ borderBottom: `1px solid ${theme.colors.altoGrey}` }}>
        <Box
          sx={muiTheme => ({
            padding: '30px 32px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [muiTheme.breakpoints.down('md')]: {
              padding: '16px',
            },
          })}
        >
          <Typography variant="subtitle2">Loan terms</Typography>
          <Chip
            sx={{ color: theme.colors.darkText, border: `1px solid ${theme.colors.darkText}`, fontSize: '14px' }}
            label="Edit"
            variant="outlined"
            onClick={() => modal.setActiveModal(Modals[`FINANCE_APPLICATION_EDIT_${FORM_STEPS.LOAN_TERMS.key}` as keyof typeof Modals])}
          />
        </Box>
      </Box>

      <Stack
        sx={muiTheme => ({
          padding: '30px 32px',
          gap: '16px',
          [muiTheme.breakpoints.down('md')]: {
            padding: '16px',
          },
        })}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography color={theme.colors.darkText}>Loan length</Typography>
          <Typography color={theme.colors.darkText}>{financeCalculatorHook?.loanLength} months</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography color={theme.colors.darkText} fontWeight="500">
            Est. loan payments
          </Typography>
          <Typography color={theme.colors.darkText} fontWeight="500">
            {loanPayment}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="caption">
            This calculation is based on a {interestRate}% APR. Your exact instalment payment may differ slightly depending on your approved
            interest rate.
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
