/* eslint-disable import/no-cycle */
import type { UseBooleanState } from '@clutch/hooks';
import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import type { ReactNode } from 'react';
import { useEffect, createContext, useContext, useRef, useState } from 'react';
import { useHistory, matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import type { PrivatePurchaseOffer } from 'src/api/privatePurchase';
import { STCnavigationStepsDropOff } from 'src/components/Pages/PrivatePurchaseOfferSchedule/static/NavigationSteps';
import { AnalyticsContext, LoginSignupModalContext } from 'src/contexts';
import { getSTCEventProperties } from 'src/eventFormatter/sellToClutch';
import { usePrivatePurchaseOfferBreakdown } from 'src/hooks';
import type { PrivatePurchaseOfferBreakdownProviderReturn } from 'src/hooks/usePrivatePurchaseOfferBreakdown';

import ClutchApi from '../../api';
import validateUuidV4 from '../../helpers/validation/uuidV4';
import { ROUTES } from '../../static';
import { AuthContext } from '../auth';
import { CheckoutContext } from '../checkout';
import { LocationContext } from '../location';
import type { GoogleMapsAddress } from '../retailCheckout/RetailCheckoutContext.types';

type PrivatePurchaseOfferContextType = {
  claim: any;
  cancelPickUp: any;
  getRedirectValues: any;
  pushSTCRudderStackEvent: any;
  updateType: any;
  fetchPriceHistory: any;
  offer: PrivatePurchaseOffer | null;
  offerBreakdown: PrivatePurchaseOfferBreakdownProviderReturn;
  isFetching: boolean;
  isClaiming: boolean;
  isUpdating: boolean;
  isPendingReviewOffer: boolean;
  isRejected: boolean;
  isSellOffer: boolean;
  isTradeinOffer: boolean;
  isAffirmPayment: UseBooleanState;
  taxSavings: any;
  activeStep: any;
  setActiveStep: any;
  loadingOffer: any;
  setLoadingOffer: any;
  isRareFind: UseBooleanState;
  offerDetailsText: string | null;
  setOfferDetailsText: any;
  vehicleDetailsText: string | null;
  setVehicleDetailsText: any;
  isCompleted: boolean;
  transferReferralReward: any;
  stcBelongsToUser: any;
  loadingPriceHistory: any;
  priceHistory: any;
  schedulingSteps: any;
  setSchedulingSteps: any;
  navigateToOffer: any;
  isScheduling: UseBooleanState;
  uberOptionAvailableAtDropoff: boolean;
  setUberAtDropoff: any;
  uberAtDropoff: boolean;
  fetch: any;
};
export const PrivatePurchaseOfferContext = createContext({} as PrivatePurchaseOfferContextType);

export const PrivatePurchaseType = {
  SELL: Symbol('SELL'),
  TRADE: Symbol('TRADE'),
  WHOLESALE: Symbol('WHOLESALE'),
  LIEN: Symbol('LIEN'),
  LEASE: Symbol('LEASE'),
  NEGATIVE_EQUITY: Symbol('NEGATIVE_EQUITY'),
};

type PrivatePurchaseOfferProviderProps = {
  children: ReactNode;
};
export const PrivatePurchaseOfferProvider = ({ children }: PrivatePurchaseOfferProviderProps) => {
  const { onModalOpen } = useContext(LoginSignupModalContext);
  const { isAuthenticated, isAuthenticating, authOnSuccessPayload, setAuthOnSuccessPayload, user } = useContext(AuthContext);
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const toastContext = useContext(ToastContext);
  const { preferredLocation, locations } = useContext(LocationContext);
  const { checkoutToSTCNavigated } = useContext(CheckoutContext);
  const history = useHistory();
  const [offer, setOffer] = useState<PrivatePurchaseOffer | null>(null);
  const [uberOptionAvailableAtDropoff, setUberOptionAvailableAtDropoff] = useState(false);
  const [offerDetailsText, setOfferDetailsText] = useState(null);
  const [vehicleDetailsText, setVehicleDetailsText] = useState(null);
  const isManualAppraisalRequired = useBooleanState();
  const isRareFind = useBooleanState({ initialState: false });
  const isFetching = useBooleanState();
  const isClaiming = useBooleanState();
  const isUpdating = useBooleanState();
  const isScheduling = useBooleanState();
  const location = useLocation();
  const [schedulingSteps, setSchedulingSteps] = useState(STCnavigationStepsDropOff);
  const [activeStep, setActiveStep] = useState(0);
  const [loadingOffer, setLoadingOffer] = useState();
  const stcBelongsToUser = useRef(true);
  const loadingPriceHistory = useBooleanState();
  const [priceHistory, setPriceHistory] = useState(null);
  const [leaseBuyoutFee, setLeaseBuyOutFee] = useState(0);
  const [taxSavings, setTaxSavings] = useState<number | null>(null);
  const offerBreakdown = usePrivatePurchaseOfferBreakdown({ offer, leaseBuyoutFee, taxSavings });
  const isAffirmPayment = useBooleanState({ initialState: false });
  const [uberAtDropoff, setUberAtDropoff] = useState(false);

  const fetch = async (id: string) => {
    try {
      isFetching.setTrue();
      isManualAppraisalRequired.setFalse();
      isRareFind.setFalse();
      const { data: privatePurchase } = await ClutchApi.privatePurchase.getPrivatePurchase({
        privatePurchaseId: id,
      });

      if (privatePurchase.id !== id) {
        const splitUrl = history.location.pathname.split('/');
        splitUrl[3] = privatePurchase.id;
        history.push(splitUrl.join('/'));
      }
      stcBelongsToUser.current = true;
      setOffer(privatePurchase);
    } catch (error: any) {
      const manualAppraisalRequired = error.response?.data?.code === 'MANUAL_APPRAISAL_REQUIRED';
      const privatePurchaseRejected = error.response?.data?.code === 'PRIVATE_PURCHASE_REJECTED';
      const userNotAuthorized = error.response?.data?.code === 'ERR_GEN_AUTHORIZATION';

      if (manualAppraisalRequired || privatePurchaseRejected) {
        setOffer(error.response.data.data);
        if (manualAppraisalRequired) isManualAppraisalRequired.setTrue();
        if (error.response.data.data.id !== id) {
          const splitUrl = history.location.pathname.split('/');
          splitUrl[3] = error.response.data.data.id;
          history.push(splitUrl.join('/'));
        }
        return;
      }

      if (userNotAuthorized) {
        stcBelongsToUser.current = false;
        return;
      }

      Sentry.captureException(error);
      history.push(ROUTES.PAGE_NOT_FOUND);
    } finally {
      isFetching.setFalse();
    }
  };

  const claim = async () => {
    if (!offer?.id) return;

    try {
      isClaiming.setTrue();

      const response = await ClutchApi.privatePurchase.claimPrivatePurchase({
        privatePurchaseId: offer?.id,
      });
      toastContext.openToast({
        type: 'success',
        message: 'Offer Successfully Saved!',
      });
      return response;
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to save your offer',
      });
      throw error;
    } finally {
      isClaiming.setFalse();
    }
  };

  const updateType = async ({ onSuccess }: { onSuccess: () => void }) => {
    if (!offer?.id) return;

    if (isUpdating.value) {
      return;
    }

    try {
      isUpdating.setTrue();

      await ClutchApi.privatePurchase.updateType({
        privatePurchaseId: offer.id,
        payload: {
          type: 'TRADE',
        },
      });
      fetch(offer?.id);
      onSuccess();
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to switch your offer to a trade-in',
      });
    } finally {
      isUpdating.setFalse();
    }
  };

  const transferReferralReward = async ({
    privatePurchaseId,
    confirmTransfer,
  }: {
    privatePurchaseId: string;
    confirmTransfer: boolean;
  }) => {
    try {
      const response = await ClutchApi.privatePurchase.transferReferral({
        privatePurchaseId,
        confirmTransfer,
      });
      return response;
    } catch (error: any) {
      const code = error?.response?.data?.code;
      if (!['INCENTIVE_TRANSFERRED_TO_SAME_FLOW', 'INCENTIVE_TRANSFERRABLE', 'INCENTIVE_NOT_TRANSFERRABLE'].includes(code)) {
        Sentry.captureException(error);
        toastContext.openToast({
          type: 'error',
          message: 'Oops! Something went wrong when trying to transfer the referral reward',
        });
      }
      if (!confirmTransfer) {
        throw error;
      }
      return null;
    }
  };

  const cancelPickUp = async ({ privatePurchaseId, payload }: { privatePurchaseId: string; payload: any }) => {
    await ClutchApi.privatePurchase.cancelActivity({
      privatePurchaseId,
      payload,
    });
  };

  const pushSTCRudderStackEvent = async ({ event }: { event: any }) => {
    const { name } = event;
    try {
      clutchDataLayer.track(name, {
        ...event,
        privatePurchase: offer, // Add the 'privatePurchase' property to the 'offer' object
        ...getSTCEventProperties({
          privatePurchase: offer,
          user,
          offerType: offerBreakdown.offerType,
        }),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const fetchPriceHistory = async ({ privatePurchaseId, extended }: { privatePurchaseId: string; extended?: boolean }) => {
    try {
      loadingPriceHistory.setTrue();
      const { data: results } = await ClutchApi.privatePurchase.getPriceHistory({ privatePurchaseId, extended });
      setPriceHistory(results);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      loadingPriceHistory.setFalse();
    }
  };

  const fetchUberAvailableAtDropoff = async (address: GoogleMapsAddress) => {
    if (!offer?.id) return;
    try {
      const { data: activityMethodsData } = await ClutchApi.privatePurchase.getActivityMethods({
        privatePurchaseId: offer.id,
        queryParams: {
          latitude: address.latitude,
          longitude: address.longitude,
        },
      });
      setUberOptionAvailableAtDropoff(activityMethodsData?.dropOff?.some(({ location }: { location: any }) => location.uberAvailable));
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: 'Oh no there was an error when trying to get the scheduling availability',
        type: 'error',
      });
    }
  };

  const isPendingReviewOffer = !isFetching.value && isManualAppraisalRequired.value;
  const isRejected = !isFetching.value && R.path(['state'], offer) === 'REJECTED';
  const isSellOffer = offer?.type === 'SELL';
  const isTradeinOffer = offer?.type === 'TRADE';

  const isCompleted = offer?.state === 'COMPLETE';

  useEffect(() => {
    const pathname = history.location.pathname;
    const stcSchedulePage = matchPath(pathname, {
      path: ROUTES.PRIVATE_PURCHASE_OFFER_SCHEDULE,
      exact: true,
      strict: true,
    });

    if (stcSchedulePage) {
      const { privatePurchaseId }: { privatePurchaseId?: string } = stcSchedulePage.params;
      if (privatePurchaseId && validateUuidV4(privatePurchaseId)) {
        fetch(privatePurchaseId);
      } else {
        setOffer(null);
        history.push(ROUTES.PAGE_NOT_FOUND);
      }
    } else {
      setOffer(null);
    }
    setActiveStep(0);
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated && !stcBelongsToUser.current) {
      onModalOpen();
      return;
    }
  }, [isAuthenticated, isAuthenticating, stcBelongsToUser.current]);

  const getRedirectValues = (id: string) => {
    if (isCompleted) {
      return {
        url: ROUTES.CONTACT,
        text: 'Questions? Contact us',
      };
    }
    if (offer?.expired) {
      return {
        url: ROUTES.PRIVATE_PURCHASE,
        text: 'Complete new appraisal',
      };
    }
    if (checkoutToSTCNavigated && isAuthenticated) {
      return {
        url: ROUTES.CHECKOUT,
        text: 'Continue to checkout',
      };
    }
    if (isPendingReviewOffer) {
      return {
        url: ROUTES.MY_DASHBOARD[2],
        text: 'Notify me when my offer is ready',
      };
    }
    if (isSellOffer && !offer?.activity) {
      const url = ROUTES.PRIVATE_PURCHASE_OFFER_SCHEDULE.replace(':privatePurchaseId', id || offer?.id);
      return { url, text: 'Schedule sale' };
    }
    if (isTradeinOffer) {
      return { url: ROUTES.SHOWROOM[0], text: 'Save and shop cars' };
    }
    return {
      url: ROUTES.MY_DASHBOARD[2],
      text: 'View offer in my profile',
    };
  };

  const navigateToOffer = () => {
    // if activity, this is a reschedule and go to dashboard
    history.push(offer?.activity ? ROUTES.MY_DASHBOARD[2] : ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', offer?.id || ''));
  };

  useEffect(() => {
    const run = async () => {
      const { type } = authOnSuccessPayload;
      if (type === 'PRIVATE_PURCHASE' && isAuthenticated) {
        const claimedOffer = await claim();
        setAuthOnSuccessPayload({});
        const { url } = getRedirectValues(claimedOffer?.data.id);
        history.push(url);
      }
    };
    run();
  }, [isAuthenticated, isAuthenticating]);

  useEffect(() => {
    const location = locations?.locations.find(loc => loc.id === preferredLocation.closestLocationId);
    const leaseBuyoutFee = location?.leaseBuyoutFee;
    setLeaseBuyOutFee(leaseBuyoutFee || 0);
    setTaxSavings(isTradeinOffer ? offer?.priceDetails?.taxSavings || 0 : null);
    if (offer?.googleMapsAddress) {
      fetchUberAvailableAtDropoff(offer.googleMapsAddress);
    }
  }, [offer]);

  return (
    <PrivatePurchaseOfferContext.Provider
      value={{
        claim,
        cancelPickUp,
        getRedirectValues,
        pushSTCRudderStackEvent,
        updateType,
        fetchPriceHistory,
        offer,
        offerBreakdown,
        isFetching: isFetching.value,
        isClaiming: isClaiming.value,
        isUpdating: isUpdating.value,
        isPendingReviewOffer,
        isRejected,
        isSellOffer,
        isTradeinOffer,
        isAffirmPayment,
        taxSavings,
        activeStep,
        setActiveStep,
        loadingOffer,
        setLoadingOffer,
        isRareFind,
        offerDetailsText,
        setOfferDetailsText,
        vehicleDetailsText,
        setVehicleDetailsText,
        isCompleted,
        transferReferralReward,
        stcBelongsToUser: stcBelongsToUser.current,
        loadingPriceHistory,
        priceHistory,
        schedulingSteps,
        setSchedulingSteps,
        navigateToOffer,
        isScheduling,
        uberOptionAvailableAtDropoff,
        setUberAtDropoff,
        uberAtDropoff,
        fetch,
      }}
    >
      {children}
    </PrivatePurchaseOfferContext.Provider>
  );
};
