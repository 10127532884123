import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as FinanceAppApi from 'src/api/financeApplications';
import { type PrivatePurchaseOffer, PrivatePurchaseState, PrivatePurchaseType } from 'src/api/privatePurchase';
import { useUserPrivatePurchases } from 'src/api/swr/useUser';
import { ActivityTypes, Errors, FormSteps, Modals } from 'src/constants';
import { defaultInterestRate } from 'src/containers/Checkout/RetailCheckout/steps/ClutchPlan/components/PlanPrice/PlanPrice';
import { AuthContext } from 'src/contexts';
import { useFlow } from 'src/hooks';
import type { FinanceApplication } from 'src/types/api';

import { flowToFormatterMap } from '../../eventFormatter/utils';
import useFinanceCalculator from '../../hooks/useFinanceCalculator';
import { useModal, useToast } from '../../stores';
import { AnalyticsContext } from '../analytics';
import { VehicleDetailsContext } from '../vehicles/vehicleDetails';
import { FORM_STEPS } from './utils';

// TODO: refine types
export type FinanceApplicationContextType = {
  trackEvent: ({ event }: { event: Record<string, any> }) => void;
  flow: {
    activeStep: {
      stepKey?: string;
      key: string;
    };
    previousStep: ({ progressPath }: { progressPath: string[] }) => void;
    navigateToSection: ({ stepKey, progressPath }: { stepKey: string; progressPath: string[] }) => void;
  };
  financeApplication: FinanceApplication;
  order: any;
  financeCalculatorHook: any;
  submitForm: (options: { stepName: string; payload?: any; skipFlowProgression?: boolean }) => Promise<void>;
  isCoApplicant: boolean;
  isLoading: boolean;
  privatePurchases?: PrivatePurchaseOffer[];
};

export const FinanceApplicationContext = createContext<FinanceApplicationContextType>(null as any);

type Props = {
  children: ReactNode;
};
export const FinanceApplicationProvider = ({ children }: Props) => {
  const params = useParams<{ taskId: string }>();
  const isLoadingState = useBooleanState({ initialState: true });
  const [financeApplication, setFinanceApplication] = useState<FinanceApplication>({} as FinanceApplication);

  const { clutchDataLayer } = useContext(AnalyticsContext);
  const { isAuthenticated, user } = useContext(AuthContext);
  const {
    vehicle,
    isLoading: isLoadingVehicle,
    fetch: fetchVehicleDetails,
    fetchVehiclePriceByLocation,
  } = useContext(VehicleDetailsContext);

  const toast = useToast();
  const modal = useModal();
  const flow = useFlow({ startingStepKey: FORM_STEPS.LOAN_TERMS.key, stepTree: FORM_STEPS });

  const { data: privatePurchases } = useUserPrivatePurchases();

  const filteredPrivatePurchases = privatePurchases?.filter(
    ({ expired, state, type }) =>
      !expired &&
      type === PrivatePurchaseType.TRADE &&
      (state === PrivatePurchaseState.APPRAISED || state === PrivatePurchaseState.MANUAL_APPRAISAL_REQUIRED),
  );

  const financeCalculatorHook = useFinanceCalculator({
    isCheckout: true, // isCheckout: true ensures we don't set default APR rate in hook
    isFinancing: true,
  });

  const getFinanceApplication = async () => {
    try {
      isLoadingState.setTrue();
      const { data } = await FinanceAppApi.getFinanceAppByTaskId({ taskId: params.taskId });

      setFinanceApplication(data);

      if (data.activeTask.status === 'COMPLETE') {
        flow.navigateToSection({ stepKey: FORM_STEPS.SUBMITTED.key, progressPath: financeApplication.progressPath });
      } else {
        const stepNum = data.progressPath?.length > 0 ? data.progressPath.length - 1 : 0;
        flow.navigateToSection({
          stepKey: data.progressPath[stepNum],
          progressPath: financeApplication.progressPath,
        });
      }

      // Update state in finance calculator hook using API response
      financeCalculatorHook.setVehicleProtections(data?.activeTask?.order?.orderProtections || []);
      financeCalculatorHook.setDownPayment(data.desiredDownPayment || data?.activeTask?.order?.downPayment || 0);
      financeCalculatorHook.setClutchPlan(data?.activeTask?.order?.orderClutchPlan);
      financeCalculatorHook.setAprRate(data?.annualPercentageRate || user?.preApproval?.interestRate || defaultInterestRate);
      financeCalculatorHook.setLoanLength(data?.desiredTermInMonths);

      // format fees before setting in financeCalculator hook
      const bosFees = data?.activeTask?.order?.payments?.filter((payment: any) => payment.type === 'FEE');

      // format credits before setting in financeCalculator hook
      const incentive = data?.activeTask?.order?.activeReferral?.incentive;
      const bosCredits = [
        {
          name: 'Online Deposit',
          price: 100,
          taxes: [],
        },
        {
          ...(incentive && {
            name: incentive.incentiveGroup?.company?.name,
            price: incentive.incentiveGroup?.incentiveAmounts?.find(
              (incentiveAmount: any) => !!incentiveAmount.refereeReward && incentiveAmount.type === 'ORDER_PLACED',
            ).refereeReward,
            taxes: [],
          }),
        },
      ];

      financeCalculatorHook.setBosFees(bosFees);
      financeCalculatorHook.setBosCredits(bosCredits);
    } catch (error) {
      Sentry.captureException(error);
      toast.open({
        message: 'Oops, looks like there was an issue starting the finance application.',
        type: 'error',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  type PostFlowStepOptions = { stepName: string; payload?: Record<string, any> };
  const postFinanceFlowStep = async (options: PostFlowStepOptions) => {
    try {
      isLoadingState.setTrue();
      const { stepName, payload } = options;

      const { data } = await FinanceAppApi.postFlowStep({ financeApplicationId: financeApplication.id, stepName, payload });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      // typescript requires the error to be typed this way using axios
      if (axios.isAxiosError(error)) {
        const errCode = error?.response?.data?.code;
        const progressPath = error?.response?.data?.progressPath;

        if (errCode === Errors.ERR_STEPS_INVALID_PROGRESS_PATH) {
          modal.setActiveModal(Modals.FINANCE_APPLICATION_INVALID_PATH, {
            onContinue: async () => {
              flow.navigateToSection({ stepKey: progressPath[progressPath.length - 1], progressPath });
              await getFinanceApplication();
            },
          });
        } else {
          toast.open({
            message: 'Oops, looks like there was an issue submitting this form.',
            type: 'error',
          });
        }
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const saleActivity = financeApplication?.activeTask?.order?.activities?.find(
    (activity: { deletedAt: Date; cancellationReason: string; type: string }) =>
      !activity.deletedAt &&
      !activity.cancellationReason &&
      (activity.type === ActivityTypes.SALE_DELIVERY || activity.type === ActivityTypes.SALE_PICKUP),
  );
  const locationId =
    saleActivity?.activitySlot?.locationId ||
    saleActivity?.locationId ||
    financeApplication?.activeTask?.order?.deliveryDetails?.location?.id;
  const vehicleId = financeApplication?.activeTask?.order?.vehicleId;
  const isCoApplicant = flow.activeStep?.key?.startsWith('CO');

  const trackEvent = ({ event }: { event: Record<string, any> }) => {
    try {
      const { name, details, action, payload, nonInteraction, isCoApplicant = false } = event;
      clutchDataLayer.track(name, {
        details,
        action,
        flow: 'finance',
        payload,
        nonInteraction,

        ...flowToFormatterMap.finance({
          vehicle,
          formDetails: financeApplication,
          isAuthenticated,
          isCoApplicant,
        }),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  type SubmitFormOptions = { stepName: string; payload?: any; skipFlowProgression?: boolean };
  const submitForm = async ({ stepName, payload, skipFlowProgression = false }: SubmitFormOptions) => {
    const data = await postFinanceFlowStep({ stepName, payload });
    if (data) {
      setFinanceApplication(data.financingApplication);
      if (data.nextStep === null) {
        flow.nextStep({ nextStepKey: FORM_STEPS.SUBMITTED.key });
      } else if (!skipFlowProgression) {
        flow.nextStep({ nextStepKey: data.nextStep });
      }
    }

    // Refresh UI, if loan terms are updated on final Review step
    if (stepName === FormSteps.LOAN_TERMS && skipFlowProgression) {
      await getFinanceApplication();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getFinanceApplication();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (vehicleId) {
      fetchVehicleDetails({ vehicleId });
    }
  }, [financeApplication?.activeTask?.order?.vehicleId]);

  useEffect(() => {
    if (vehicleId && locationId) {
      fetchVehiclePriceByLocation({ vehicleId, locationId });
    }
  }, [locationId]);

  return (
    <FinanceApplicationContext.Provider
      value={{
        flow,
        order: financeApplication?.activeTask?.order,
        financeApplication,
        isLoading: isLoadingVehicle || isLoadingState.value,
        financeCalculatorHook,
        trackEvent,
        submitForm,
        privatePurchases: filteredPrivatePurchases,
        isCoApplicant,
      }}
    >
      {children}
    </FinanceApplicationContext.Provider>
  );
};
