import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { useHistory } from 'react-router';

import { useUserBanner } from 'src/api/swr/useUser';
import { AnalyticsContext } from 'src/contexts';
import { ROUTES } from 'src/static';
import { theme } from 'src/theme';

import { CountdownTimer } from '../CountdownTimer/CountdownTimer';
import { PulsingCircle } from '../molecules/atoms/PulsingCircle';
import { BannerContainer, BannerLink } from './components';

export const STCBanner = ({ privatePurchase }: { privatePurchase: any }) => {
  const analyticsContext = useContext(AnalyticsContext);
  const history = useHistory();
  const { mutate: updateUserBanner } = useUserBanner();

  const handleViewOffer = () => {
    const event = 'STC Banner Offer Clicked';
    analyticsContext.clutchDataLayer.track(event, {
      event,
      action: 'Click',
      flow: 'STC',
    });

    history.push({
      pathname: ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', privatePurchase.id),
    });
  };
  return (
    <BannerContainer darkBackground={true} keyVal="STC">
      <Box display="flex" alignItems="center">
        <PulsingCircle bgcolor={theme.colors.primary} sx={{ paddingRight: '10px' }} />
        <Typography>
          The offer for your {privatePurchase.year} {privatePurchase.make} {privatePurchase.model} expires in
          <b>
            <CountdownTimer
              compact
              endDateTime={privatePurchase.expiredAt}
              segmentProps={{
                hours: { customLabel: { single: 'h', plural: 'h' } },
                seconds: { showOnZero: true },
              }}
              onEnd={() => updateUserBanner()}
            />
            &nbsp; &nbsp;
          </b>
        </Typography>
      </Box>
      <BannerLink mobileLabel="View" label="View Offer" onClick={handleViewOffer} />
    </BannerContainer>
  );
};
