import { Stack } from '@mui/material';
import { Box } from 'rebass';

import { MoneyMask } from 'src/helpers/masks';

import * as Styled from '../OrderDetails.styles';

export const Downpayment = ({ downPayment }: { downPayment: number }) => {
  if (!downPayment) {
    return null;
  }

  return (
    <Box paddingX={1} marginBottom={3}>
      <Stack direction="row" justifyContent="space-between">
        <Styled.BreakdownHeader>Downpayment</Styled.BreakdownHeader>
        <Styled.BreakdownHeader>-{MoneyMask(downPayment)}</Styled.BreakdownHeader>
      </Stack>
    </Box>
  );
};
