import { useContext, useEffect } from 'react';

import { BannerType, isActiveReferralBanner, isPrivatePurchaseBanner, useUserBanner } from 'src/api/swr/useUser';
import { AuthContext } from 'src/contexts';
import { theme } from 'src/theme';

import { PulsingCircle } from '../molecules/atoms/PulsingCircle';
import { BannerContainer } from './components';
import { DefaultBanner } from './DefaultBanner';
import { ReferralBanner } from './ReferralBanner';
import { STCBanner } from './STCBanner';

export const Banner = () => {
  const authContext = useContext(AuthContext);

  const { isAuthenticated, user } = authContext;

  const { data: userBanner, isLoading: isLoadingUserBanner } = useUserBanner(isAuthenticated, user?.id);

  if (isAuthenticated && isLoadingUserBanner)
    return (
      <BannerContainer darkBackground={true} keyVal="loadingBanner">
        <PulsingCircle bgcolor={theme.colors.primary} sx={{ paddingRight: '10px' }} />
      </BannerContainer>
    );

  let banner = <DefaultBanner />;

  switch (userBanner?.type) {
    case BannerType.STC_OFFER_BANNER:
      if (isPrivatePurchaseBanner(userBanner.data)) banner = <STCBanner privatePurchase={userBanner.data} />;
      break;
    case BannerType.ACTIVE_REFERRAL_BANNER:
      if (isActiveReferralBanner(userBanner.data)) banner = <ReferralBanner refereeReward={userBanner.data.refereeReward} />;
      break;
  }

  return banner;
};
