import * as R from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { PrivatePurchaseOfferContext, PrivatePurchaseShellContext } from 'src/contexts';
import { privatePurchaseFlowSteps as flowSteps } from 'src/contexts/privatePurchase/config';
import vehicleInformationSteps from 'src/contexts/privatePurchaseShell/flow/PrivatePurchaseShellStepTree';
import orderConstants from 'src/static/order-constants';
import { ROUTES } from 'src/static/routes';

type Step = {
  key: string;
  sectionLabel?: string;
};

const offerSteps = {
  VEHICLE_OFFER: {
    key: 'VEHICLE_OFFER',
    sectionLabel: 'Vehicle Offer',
  },
};

const usePrivatePurchaseFlow = () => {
  const { handleEditShellButton, privatePurchaseShellFlow } = useContext(PrivatePurchaseShellContext);
  const {
    offer,
    activeStep: offerActiveStep,
    setActiveStep: setOfferActiveStep,
    schedulingSteps,
    navigateToOffer,
    isScheduling,
  } = useContext(PrivatePurchaseOfferContext);
  const [activeSection, setActiveSection] = useState<Step>(flowSteps.VEHICLE_INFORMATION);
  const [activeStep, setActiveStep] = useState<Step>(privatePurchaseShellFlow?.activeStep);

  const history = useHistory();
  const { pathname } = useLocation();

  // We exclude the VIN step and SHELL_COMPLETE step from the STC flow
  const sanitizedVehicleInformationSteps = R.omit(
    [vehicleInformationSteps.VIN_OR_PLATE_SELECT.key, vehicleInformationSteps.SHELL_COMPLETE.key],
    vehicleInformationSteps,
  );

  // Calculate total progress, across all granular steps
  const allSteps = [...Object.values(sanitizedVehicleInformationSteps), ...Object.values(offerSteps), ...schedulingSteps];
  const index = activeStep ? allSteps.findIndex((step: any) => step.key === activeStep.key) : 0;
  const totalProgressPercentage = ((index + 1) / allSteps.length) * 100;

  // Calculate individual progress percentages for each section, for mobile/tablet displays
  const vehicleInformationProgressPercentage =
    index + 1 >= Object.keys(sanitizedVehicleInformationSteps).length
      ? 100
      : ((index + 1) / Object.keys(sanitizedVehicleInformationSteps).length) * 100;
  const offerProgressPercentage = index + 1 > Object.keys(sanitizedVehicleInformationSteps).length ? 100 : 0;
  const scheduleProgressPercentage =
    ((index + 1 - Object.keys(sanitizedVehicleInformationSteps).length - Object.keys(offerSteps).length) / schedulingSteps.length) * 100;

  // Update active section (top-level) and active step (granular) using URL pathname
  useEffect(() => {
    if (pathname.includes('/schedule')) {
      setActiveSection(flowSteps.SCHEDULE_SALE);
      setActiveStep(schedulingSteps[offerActiveStep]);
    } else if (pathname.includes('/offer')) {
      setActiveSection(flowSteps.VEHICLE_OFFER);
      setActiveStep(offerSteps.VEHICLE_OFFER);
    } else {
      setActiveSection(flowSteps.VEHICLE_INFORMATION);
      setActiveStep(privatePurchaseShellFlow?.activeStep);
    }
  }, [pathname, privatePurchaseShellFlow?.activeStep, offerActiveStep]);

  const isOfferSection = activeSection === flowSteps.VEHICLE_OFFER;

  const isInProgress = ![
    orderConstants.STATUS.CANCELLED,
    orderConstants.STATUS.COMPLETED,
    orderConstants.STATUS.PENDING,
    orderConstants.STATUS.RETURNED,
  ].includes(offer?.activity?.status);

  const goBack = () => {
    switch (activeSection) {
      case flowSteps.SCHEDULE_SALE:
        if (offerActiveStep === 0) {
          navigateToOffer();
        } else {
          setOfferActiveStep(offerActiveStep - 1);
        }
        break;
      case flowSteps.VEHICLE_OFFER:
        handleEditShellButton({ privatePurchaseId: offer.id });
        break;
      default:
        if (activeStep === vehicleInformationSteps.VEHICLE_INFORMATION) {
          history.push(ROUTES.PRIVATE_PURCHASE);
        }
        if (activeStep === vehicleInformationSteps.VEHICLE_ADDITIONAL_FEATURES) {
          privatePurchaseShellFlow.navigateToSection({ stepKey: vehicleInformationSteps.VEHICLE_INFORMATION.key });
        }
        if (activeStep === vehicleInformationSteps.VEHICLE_CONDITION_AND_HISTORY) {
          privatePurchaseShellFlow.navigateToSection({ stepKey: vehicleInformationSteps.VEHICLE_ADDITIONAL_FEATURES.key });
        }
    }
  };

  return {
    offer,
    handleEditShellButton,
    activeSection,
    activeStep,
    totalProgressPercentage,
    vehicleInformationProgressPercentage,
    offerProgressPercentage,
    scheduleProgressPercentage,
    isOfferSection,
    isInProgress,
    isScheduling: isScheduling.value,
    goBack,
  };
};

export default usePrivatePurchaseFlow;
