import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import * as Sentry from '@sentry/browser';
import * as ErrorComponents from './components';
import { forceRefreshWithInterval } from './utils';
import { VERSION } from '../../config';

const withErrorBoundary = (WrappedComponent, { ErrorComponent = ErrorComponents.DefaultError, isRoute = false } = {}) => {
  class ErrorBoundComponent extends Component {
    state = {
      isError: false,
      error: {},
    };

    componentDidCatch(error, errorInfo) {
      this.setState({
        isError: true,
        error,
      });

      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        scope.setTag('Route', this.props.location.pathname);
        scope.setTag('Version', VERSION);

        Sentry.captureException(error);

        isRoute && process.env.NODE_ENV !== 'development' && forceRefreshWithInterval(30);
      });
    }

    render() {
      return this.state.isError ? (
        <ErrorComponent {...this.props} error={this.state.error} showReportDialog={Sentry.showReportDialog} />
      ) : (
        <WrappedComponent {...this.props} />
      );
    }
  }

  ErrorBoundComponent.propTypes = {
    location: PropTypes.object.isRequired,
  };

  return withRouter(ErrorBoundComponent);
};

export { ErrorComponents };
export default withErrorBoundary;
