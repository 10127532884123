export const POPULAR_MODELS = [
  { make: 'Honda', model: 'Civic' },
  { make: 'Toyota', model: 'Corolla' },
  { make: 'Hyundai', model: 'Elantra' },
  { make: 'Toyota', model: 'RAV4' },
  { make: 'Nissan', model: 'Rogue' },
  { make: 'Honda', model: 'CR-V' },
  { make: 'Tesla', model: 'Model 3' },
  { make: 'Mazda', model: 'CX-5' },
  { make: 'Kia', model: 'Forte' },
  { make: 'Toyota', model: 'Camry' },
  { make: 'Mazda', model: 'Mazda3' },
];

export const POPULAR_LOCATIONS = [
  { city: 'Toronto', provinceCode: 'ON' },
  { city: 'Brampton', provinceCode: 'ON' },
  { city: 'Mississauga', provinceCode: 'ON' },
  { city: 'Markham', provinceCode: 'ON' },
  { city: 'Ottawa', provinceCode: 'ON' },
  { city: 'Hamilton', provinceCode: 'ON' },
  { city: 'London', provinceCode: 'ON' },
  { city: 'Oshawa', provinceCode: 'ON' },
  { city: 'Kitchener', provinceCode: 'ON' },
  { city: 'Newmarket', provinceCode: 'ON' },
  { city: 'Barrie', provinceCode: 'ON' },
  { city: 'Kingston', provinceCode: 'ON' },
  { city: 'Windsor', provinceCode: 'ON' },
  { city: 'Guelph', provinceCode: 'ON' },
  { city: 'Halifax', provinceCode: 'NS' },
  { city: 'Dartmouth', provinceCode: 'NS' },
  { city: 'Sydney', provinceCode: 'NS' },
  { city: 'Moncton', provinceCode: 'NB' },
  { city: 'Fredericton', provinceCode: 'NB' },
  { city: 'Saint John', provinceCode: 'NB' },
];
