import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTabInactive, useTimeOut } from '../../hooks';
import RedClock from 'src/assets/icons/redClock.svg';
import formatSeconds from '../../helpers/formatSeconds';
import * as Styled from './styles';

const Timer = ({ topNavigationOnly, sessionEndTime, isLoading }) => {
  const getTimeRemaining = () => sessionEndTime - Math.floor(new Date().getTime() / 1000);

  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  useTimeOut(() => getTimeRemaining() >= 0 && setTimeRemaining(getTimeRemaining));

  const onFocus = () => {
    setTimeRemaining(getTimeRemaining);
  };

  const { addTabListeners } = useTabInactive(onFocus);
  useEffect(() => {
    addTabListeners();
  }, []);

  return (
    <Styled.Container hideTimer={isLoading} topNavigationOnly={topNavigationOnly}>
      <Styled.TimeIcon src={RedClock} alt="clock" />
      <Styled.Time>{formatSeconds({ totalSeconds: timeRemaining })}</Styled.Time>
    </Styled.Container>
  );
};

Timer.propTypes = {
  topNavigationOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  sessionEndTime: PropTypes.number,
};

Timer.defaultProps = {
  topNavigationOnly: false,
  isLoading: false,
  sessionEndTime: null,
};

export default Timer;
