export const RetailEventMap = {
  // -- Sell To Clutch Steps
  SELL_TO_CLUTCH: {
    name: 'Trade in Continued',
    details: 'User clicks continue to exit the STC flow in checkout',
  },
  SELL_TO_CLUTCH_STARTED: {
    name: 'Trade in Started',
    details: '',
  },
  SELL_TO_CLUTCH_VIN_OR_PLATE: {
    name: 'Vin/Plate Entered',
    details: 'User entered a vin/plate and proceeds.',
  },
  SELL_TO_CLUTCH_DETAILS: {
    name: 'Continue STC Details Clicked',
    details: 'User clicks continue after entering vehicle details',
  },
  SELL_TO_CLUTCH_DETAILS_VIEWED: {
    name: 'STC Details Viewed',
    details: 'User views the details page for STC',
  },
  SELL_TO_CLUTCH_ADDITIONAL_FEATURES: {
    name: 'Continue STC Additional Features Clicked',
    details: 'User clicks continue after entering any additional features on their STC',
  },
  SELL_TO_CLUTCH_ADDITIONAL_FEATURES_FACTORY: {
    name: 'Vehicle Factory Features Selected',
    details: 'User selects features and packages from default factory options',
  },
  SELL_TO_CLUTCH_ADDITIONAL_FEATURES_VIEWED: {
    name: 'STC Additional Features Viewed',
    details: 'User views the additional features page for STC',
  },
  SELL_TO_CLUTCH_CONDITION: {
    name: 'Continue STC Condition Clicked',
    details: 'User clicks continue after entering condition',
  },
  SELL_TO_CLUTCH_CONDITION_VIEWED: {
    name: 'STC Condition Viewed',
    details: 'User views the condition page for STC',
  },
  SELL_TO_CLUTCH_GET_OFFER: {
    name: 'Get Offer Clicked',
    details: 'User clicks get offer',
  },
  SELL_TO_CLUTCH_OFFER_VIEWED: {
    name: 'STC Shell Offer Viewed',
    details: 'User views an STC shell offer page',
  },

  // -- Buyer Information Step
  BUYER_INFORMATION: {
    name: 'Buyer Information Page Continued',
    details: 'User clicked "Continue"',
  },
  BUYER_INFORMATION_VIEWED: {
    name: 'Buyer Information Page Viewed',
    details: 'User views the Buyer Information page',
  },
  BUYER_INFORMATION_COBUYER_ADDED: {
    name: 'Co-Buyer Added',
    details: 'User added a co-buyer',
  },
  BUYER_INFORMATION_COBUYER_REMOVED: {
    name: 'Co-Buyer Removed',
    details: 'User removed co-buyer',
  },

  // -- Clutch Plan Step

  CLUTCH_PLAN: {
    name: 'Clutch Plan Selected',
    details: 'User selected Clutch plan',
  },

  // -- Payment Type Step
  PAYMENT_TYPE: {
    name: 'Payment Method Selected',
    details: 'User selects a payment method',
  },
  PAYMENT_TYPE_VIEWED: {
    name: 'Payment Method Page Viewed',
    details: 'User views the Payment Methods page',
  },

  // -- Borrower Information Step
  BORROWER_INFORMATION: {
    name: 'Borrower Information Page Continued',
    details: 'User clicked "Continue"',
  },
  BORROWER_INFORMATION_VIEWED: {
    name: 'Borrower Information Page Viewed',
    details: 'User views the Borrower Information page',
  },

  // -- Estimate Credit Score Step
  ESTIMATE_CREDIT_SCORE: {
    name: 'Estimate Credit Score Page Continued',
    details: 'User estimates their credit score and clicks "Continue"',
  },
  ESTIMATE_CREDIT_SCORE_VIEWED: {
    name: 'Estimate Credit Score Page Viewed',
    details: 'User views the estimate credit score page',
  },

  // -- Finance Calculator Step
  FINANCE_CALCULATOR: {
    name: 'Financing Continued',
    details: 'User proceeds through financing in checkout with pre-approval loan rate',
  },

  // -- Protection Customization Step
  PROTECTION_CUSTOMIZATION: {
    name: 'Clutch Care Continued',
    details: 'User clicked the "See my warranty" CTA',
  },
  PROTECTION_CUSTOMIZATION_VIEWED: {
    name: 'Warranty Page 1 Viewed',
    details: 'User views the Warranty Information Page',
  },

  // -- Warranty Select Step
  WARRANTIES_SELECT: {
    name: 'Warranty Page 2 Continued',
    details: 'User clicked "Continue"',
  },
  WARRANTIES_SELECT_VIEWED: {
    name: 'Warranty Page 2 Viewed',
    details: 'User views the Warranty Recommendations page',
  },
  WARRANTIES_SELECT_ADDED: {
    name: 'Product Added',
    details: 'User added a warranty product',
  },
  WARRANTIES_SELECT_EDITED: {
    name: 'Coverage Edited',
    details: 'User clicked the edit button ',
  },

  // -- GAP Select Step
  GAP_SELECT: {
    name: 'Gap Page Continued',
    details: 'User clicked to continue on GAP Page',
  },
  GAP_SELECT_VIEWED: {
    name: 'Gap Page Viewed',
    details: 'User views the Gap Insurance Page',
  },
  GAP_SELECT_ADDED: {
    name: 'Product Added',
    details: 'User added a GAP Insurance',
  },

  // -- Reception Type Step
  RECEPTION_TYPE: {
    name: 'Delivery Method Selected',
    details: 'User selected a delivery method',
  },
  RECEPTION_TYPE_VIEWED: {
    name: 'Delivery Method Page Viewed',
    details: 'User views the Delivery Methods page',
  },

  // -- Reception Time Step
  RECEPTION_TIME: {
    name: 'Delivery Time Selected',
    details: 'User selected a delivery time',
  },
  RECEPTION_TIME_VIEWED: {
    name: 'Delivery Schedule Page Viewed',
    details: 'User views the Delivery Schedule page',
  },

  // -- Drivers License Step
  DRIVERS_LICENSE: {
    name: 'License Uploaded',
    details: 'User uploaded their license',
  },
  DRIVERS_LICENSE_VIEWED: {
    name: 'License Upload Page Viewed',
    details: 'User views the License Upload page',
  },

  // -- Review Step
  REVIEW: {
    name: 'Deposit Submitted',
    details: 'User submitted their deposit',
  },
  REVIEW_VIEWED: {
    name: 'Cart Viewed',
    details: 'User lands on the checkout review page',
  },

  // -- Confirmation Step
  CONFIRMATION_VIEWED: {
    name: 'Checkout Summary Viewed',
    details: 'User views the checkout summary page',
  },

  START_FINANCE_APPLICATION_CLICKED: {
    name: 'Start Finance Application Clicked',
    details: 'User clicks the start finance application button',
  },
};
