import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/static/routes';

import { CITY_DETAILS } from './citySTC.data';
import type { CityData } from './citySTC.types';

export const getCityNames = (filterFooter = true): string[] => {
  return Object.values(CITY_DETAILS)
    .filter(city => !filterFooter || city.highlightInFooter)
    .map(cityData => cityData.name);
};

// To handle St. Catharines and Cape Breton
export const normalizeCityName = (name: string): string => {
  return name.toLowerCase().replace(/[_. ]/g, '-').replace(/-+/g, '-'); // Remove consecutive hyphens
};

const getCityKey = (cityName: string): string | undefined => {
  return Object.keys(CITY_DETAILS).find(key => normalizeCityName(key) === normalizeCityName(cityName));
};

export const getCityFromUrl = (pathname: string): string | undefined => {
  const match = matchPath<{ city?: string }>(pathname, ROUTES.SELL_MY_CAR.BASE + ROUTES.SELL_MY_CAR.PATH);

  return match?.params?.city;
};

export const findCityFromPathname = (pathname: string): CityData | undefined => {
  const city = getCityFromUrl(pathname);
  if (city) {
    const cityKey = getCityKey(city);
    if (cityKey) {
      return CITY_DETAILS[cityKey];
    }
  }
  return undefined;
};

export function getCityString(prefix: string, city: string): string {
  return city && city.trim() !== '' ? ` ${prefix} ${city.trim()}` : '';
}

export function getSellMyCarForCityUrl(city: string): string {
  return `${ROUTES.SELL_MY_CAR.BASE}-${normalizeCityName(city)}`;
}
