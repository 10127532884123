import type { ClutchPackage } from 'src/api/clutchPackages';

import type { Activity } from './activity';
import type { Address, Location } from './location';
import type { Vehicle } from './vehicle';

export type Order = {
  isLocked: boolean;
  voided: boolean;
  taskStatus: any;
  id: string;
  referenceCode: string;
  pendingAt: string;
  completedAt: any;
  cancelledAt: any;
  returnedAt: any;
  sessionEndDate: string;
  airtableId: any;
  hubSpotDealId: string;
  personaInquiryId: string;
  dealType: string;
  paymentMethod: any;
  paymentType: string;
  status: string;
  nativeStatus: string;
  owner: Owner;
  coOwner: CoOwner;
  coSigner: any;
  billingAddress: BillingAddress;
  shippingFee: number;
  downPayment: number;
  loanTermLength: any;
  creditCheckDetails: CreditCheckDetails;
  vehicleWarrantyNeeds: any;
  hasDeferredPrivatePurchase: boolean;
  wasPreOrder: boolean;
  progressPath: string[];
  cancellationReason: any;
  cancellationReasonDescription: any;
  returnReason: any;
  returnReasonOther: any;
  deliveryDetails: DeliveryDetails;
  version: number;
  groupingId: string;
  billOfSaleGeneratedAt: any;
  sentToCustomerAt: any;
  voidedAt: any;
  helloSignRequestId: any;
  encryptedKey: string;
  driverLicenseInfo: {
    type: string;
    data: number[];
  };
  coOwnerDriverLicenseInfo?: {
    type: string;
    data: number[];
  };
  bosConfiguration: {
    showAlbertaAuthForm: boolean;
  };
  locationMigration: boolean;
  createdAt: string;
  updatedAt: string;
  vehicleId: number;
  vehicleOriginLocationId: string;
  userId: string;
  companyId: any;
  bosContactId: any;
  billOfSaleGeneratedById: any;
  dealOwnerId: any;
  decisionId: any;
  vehicle: Vehicle;
  orderProtections: OrderProtection[];
  activities: Activity[];
  orderClutchPlan: ClutchPackage;
};

export type Owner = {
  lastName: string;
  firstName: string;
  phoneNumber: string;
};

export type CoOwner = {
  email: string;
  lastName: string;
  firstName: string;
  showOnBos: boolean;
  showOnAlbertaAuthForm: boolean;
};

export type BillingAddress = {
  raw: string;
  city: string;
  street: string;
  country: string;
  latitude: number;
  province: string;
  longitude: number;
  postalCode: string;
};

export enum Classification {
  GOOD = 'GOOD',
  AVERAGE = 'AVERAGE',
  POOR = 'POOR',
}

export type CreditCheckDetails = {
  classification?: Classification;
  estimatedInterestRate?: number;
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  annualIncome: number;
  address: {
    city: string;
    country: string;
    province: string;
    provinceCode?: string | null;
    postalCode: string;
    street: string;
    raw?: string | null;
    apartment?: string;
    longitude: number | null;
    latitude: number | null;
    name?: string | null;
  };
};

export type DeliveryDetails = {
  type: string;
  address: Address;
  location: Location;
  scheduledAt: {
    date: string;
    time: string;
  };
};

export enum OrderProtectionType {
  EXTENDED_WARRANTY = 'EXTENDED_WARRANTY',
  GAP = 'GAP',
  TIRE_AND_RIM = 'TIRE_AND_RIM',
  RUST = 'RUST',
  UNDERCOAT = 'UNDERCOAT',
}

export type OrderProtection = {
  name: string;
  id: string;
  protectionTypeName: string;
  protectionName: string;
  protectionType: OrderProtectionType;
  price: number;
  taxes: Tax[];
  createdAt: string;
  updatedAt: string;
  orderId: string;
  maximumClaim: number | null;
  distance: number | null;
  term: number | null;
  deductible: number | null;
  paidDirectlyToProvider: boolean | null;
  underManufacturerWarranty: number | null;
};

export type Tax = {
  name: string;
  rate: number;
  amount: number;
};
