import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { theme } from 'src/theme';
import MenuIcon from '@material-ui/icons/Menu';

type WrapperProps = {
  offsetTop: number;
  isHeaderHidden: boolean;
  isRelative: boolean;
};

export const Wrapper = styled.header<WrapperProps>`
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'sticky')};
  top: ${({ offsetTop }) => offsetTop};
  background-color: ${theme.colors.white};
  ${({ isHeaderHidden }) => (isHeaderHidden ? 'display: none;' : '')}
  left: 0;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  ${({ theme }) => theme.media.xs`
   box-shadow: none;
  `}
`;

export const Header = styled.nav`
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 20px;
  height: 68px;
  width: 100vw;
  justify-content: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  will-change: background-color, color, transform;
  transition:
    background-color 0.1s linear,
    color 0.1s linear,
    transform 200ms ease-out;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    text-decoration: none;
    color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  }

  z-index: ${({ theme }) => theme.zIndex.siteHeader};

  ${({ theme }) =>
    theme.media.md`
      height: 56px;
      padding: 0 24px;
    `}
  ${({ theme }) =>
    theme.media.xs`
      height: 40px;
      padding: 0 16px;
    `}
`;

export const Left = muiStyled(Stack)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const DesktopHeaderLogoContainer = styled(Stack)`
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  width: 100px;
  ${({ theme }) => theme.media.xs`
    width: 67px;
  `}
`;

export const CTAButtonContainer = styled(Box)`
  display: 'flex'
  margin-left: 24px;
`;

export const LoginSignUpButtonContainer = styled(Box)`
  margin: 0px 24px;
  ${({ theme }) => theme.media.md`
    display: none;
  `}
`;

export const Right = styled(Box)`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  width: fit-content;
  min-width: fit-content;
  flex-direction: row;
`;

export const HamburgerMenuButton = styled(MenuIcon)`
  padding-right: 0px;
  && {
    display: none;
  }
  ${({ theme }) => theme.media.md`
  && {
    display: flex;
    font-size: 18px;
    cursor: pointer;
    font-size: 24px;
    color: ${theme.colors.neutralDarkGrey};
    }
  `}
`;
