import * as Styled from '../OrderDetails.styles';
import { useBooleanState } from '@clutch/hooks';
import { BreakdownButton } from '../BreakdownButton';
import { Collapse, Typography } from '@mui/material';
import { MoneyMask } from 'src/helpers/masks';

type TotalProps = {
  biWeeklyPayment: number;
  finalPriceIncFees: number;
  loanTermLength: number;
  hideFinancingInfo?: boolean;
  isFinancing: boolean;
  showLoanTerm?: boolean;
  totalText?: string;
};

export const Total = ({
  biWeeklyPayment,
  finalPriceIncFees,
  loanTermLength,
  hideFinancingInfo,
  isFinancing,
  showLoanTerm = false,
  totalText = 'Total',
}: TotalProps) => {
  const financingCopyExpandedState = useBooleanState();

  const isVehicleHoldAfterTax = finalPriceIncFees < 0;

  const paymentAmount = isFinancing ? biWeeklyPayment : finalPriceIncFees;
  const formattedAmount = MoneyMask(paymentAmount < 0 ? -paymentAmount : paymentAmount);
  const totalString = `${formattedAmount}${isFinancing ? ' / biweekly' : ''}`;

  return (
    <Styled.TotalSectionContent>
      <Styled.TotalRow>
        {isVehicleHoldAfterTax ? (
          <Styled.TotalText>We pay you</Styled.TotalText>
        ) : (
          <Styled.TotalText>
            {totalText}
            {isFinancing && <Styled.TotalTextNote> (est.)</Styled.TotalTextNote>}
          </Styled.TotalText>
        )}
        <Styled.TotalText>{totalString}</Styled.TotalText>
      </Styled.TotalRow>
      {showLoanTerm && isFinancing && (
        <Typography marginTop="4px" alignSelf="end" variant="caption" color="textSecondary">
          {loanTermLength} month term
        </Typography>
      )}
      {isFinancing && !hideFinancingInfo && (
        <>
          <BreakdownButton text="View financing details" toggleState={financingCopyExpandedState} />
          <Collapse in={financingCopyExpandedState.value}>
            <Styled.FinancingDetailsCopy>
              The bi-weekly payment amounts displayed on our website are estimated and provided for informational purposes only. Actual loan
              terms, including your bi-weekly payment, may vary and are subject to approval based on your creditworthiness. Approval for
              financing is determined On Approved Credit (OAC) only. There is no guarantee that you will qualify for the estimated loan
              terms or payments shown.
            </Styled.FinancingDetailsCopy>
          </Collapse>
        </>
      )}
      {isVehicleHoldAfterTax && (
        <Typography marginTop="8px" variant="caption">
          You will receive this within 3 business days of your trade
        </Typography>
      )}
    </Styled.TotalSectionContent>
  );
};
