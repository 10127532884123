import { provinceMap } from '@clutch/clutch-common/lib/constants';
import { billOfSale, financing, getMaxLoanLengthForYear } from '@clutch/clutch-common/lib/utils';
import formatTaxNames from '@clutch/clutch-common/lib/utils/bill-of-sale/helpers/format-tax-names';
import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { useContext } from 'react';

import type { PackageWarranty, Protection } from 'src/api/clutchPackages';
import { PackageBenefits, type ClutchPackage, PackageWarrantyType } from 'src/api/clutchPackages';
import { OrderPaymentTypes } from 'src/containers/Checkout/Checkout.types';
import { formatAncillaryProtectionsForBos } from 'src/helpers/format-protections-for-bos';
import ORDER_CONSTANTS from 'src/static/order-constants';
import { AuthContext, LocationContext } from '../contexts';

enum DealType {
  CASH = 'CASH',
  FINANCE = 'Finance',
}

const { calculateFinancePayments } = financing;

export type UseFinanceProps = {
  tradeInPrice: number;
  tradeInTaxSavingsInput: number;
  lienAmount: number;
  isFinancing: boolean;
  interestRateInput: number;
  checkout: any;
  clutchPlans: ClutchPackage[];
  vehicle: any;
  selectedWarranty: any;
  warrantyOptionsAvailable: any;
  defaultRustProtection: any;
  defaultTireAndRimProtection: any;
};

const useFinanceForPlanPricing = ({
  tradeInPrice = 0,
  tradeInTaxSavingsInput = 0,
  isFinancing = false,
  lienAmount = 0,
  interestRateInput = 0,
  checkout,
  vehicle,
  clutchPlans,
  selectedWarranty,
  warrantyOptionsAvailable,
  defaultRustProtection,
  defaultTireAndRimProtection,
}: UseFinanceProps) => {
  const { user } = useContext(AuthContext);
  const locationContext = useContext(LocationContext);
  // The order's delivery address should be the source of truth for determining
  // the order's province code (we need this to calculate taxes, among other things).
  // Fallback location: the user's preferred location.
  const location = checkout?.deliveryDetails?.location || locationContext?.closestLocation;
  const provinceCode = R.pathOr('ON', ['region', 'provinceId'], location);

  const vehiclePrice = vehicle?.price || 0;

  // setting states
  const downPayment = user?.settings?.downPayment || 0;
  const loanLength = vehicle?.year && getMaxLoanLengthForYear(vehicle.year);
  const interestRate = interestRateInput;

  const discountAmount = vehicle.amountDiscounted || vehiclePrice * vehicle.percentDiscounted || 0;
  const vehicleSellingPrice = vehiclePrice - discountAmount;

  let isAnyPositiveEquity = false;

  const getVehicleNumDaysListed = () => {
    const currentDate = dayjs();
    const firstVisibleDate = dayjs(vehicle.firstVisibleDate);
    return currentDate.diff(firstVisibleDate, 'days');
  };

  const provinceFees = vehicleSellingPrice
    ? [
        {
          name: 'Shipping',
          price: vehicle.shippingFee,
          taxes: formatTaxNames({
            taxNames: provinceMap[provinceCode].shippingTaxes,
            provinceCode,
          }),
        },
        ...billOfSale.formatProvinceFees({
          provinceCode,
          vehiclePrice: vehicleSellingPrice,
          loanTermLength: loanLength,
          orderType: user?.isDealer ? ORDER_CONSTANTS.DEAL_TYPE.DEALER : ORDER_CONSTANTS.DEAL_TYPE.RETAIL,
          dealType: isFinancing ? DealType.FINANCE : DealType.CASH,
          daysListed: getVehicleNumDaysListed(),
          dateOfBirth: user?.dateOfBirth,
          familyNameOrCompanyName: user?.lastName,
        }),
      ]
    : [];

  const getBillOfSaleValues = (bosParams: any) => {
    try {
      return billOfSale.generateBillOfSale(bosParams);
    } catch (error) {
      Sentry.captureMessage('Unable to calculate bill of sale');
      Sentry.captureException(error);
    }

    return {
      taxableSubtotal: 0,
      taxes: [],
      totalTaxAmount: 0,
      fees: {},
    };
  };

  const allPlanPricing = clutchPlans.reduce((acc: Record<string, any>, plan) => {
    const appliedProtections: (PackageWarranty | Protection)[] = checkout?.vehicleProtections || [];
    if (plan?.warrantyType === PackageWarrantyType.CUSTOM && selectedWarranty) {
      appliedProtections.push(selectedWarranty);
    }

    if (plan?.packageBenefits[PackageBenefits.RUST] && defaultRustProtection) {
      appliedProtections.push(defaultRustProtection);
    }

    if (plan?.packageBenefits[PackageBenefits.TIRE_AND_RIM] && defaultTireAndRimProtection) {
      appliedProtections.push(defaultTireAndRimProtection);
    }

    if (plan.warrantyType === PackageWarrantyType.CUSTOM && !warrantyOptionsAvailable) {
      plan = { ...plan, clutchFee: plan.clutchFee + 250000 };
    }

    const bosParams = {
      vehiclePrice: vehicleSellingPrice,
      downPayment,
      provinceCode,
      dealType: isFinancing ? DealType.FINANCE : DealType.CASH,
      privatePurchases: [{ price: tradeInPrice, lienAmount, taxSavings: tradeInTaxSavingsInput }], // BOS util expects trade-in price separate from tax savings amount
      protections: formatAncillaryProtectionsForBos({ ancillaries: appliedProtections, provinceCode }),
      credits: [
        {
          name: 'Online Deposit',
          price: 100,
          taxes: [],
        },
        {
          ...(checkout?.incentive?.incentiveAmount && {
            name: checkout?.incentive?.isReferral ? 'Referral Reward' : `${checkout.incentive?.company?.name} Discount`,
            price: checkout?.incentive?.incentiveAmount,
            taxes: [],
          }),
        },
        ...(checkout?.payments?.filter((payment: any) => payment.type === OrderPaymentTypes.CREDIT) || []),
      ],
      fees: checkout?.payments?.filter((payment: any) => payment.type === OrderPaymentTypes.FEE) || provinceFees,
      vehicleFuelType: R.path(['fuelType', 'name'], vehicle),
      vehicleMileage: R.path(['mileage'], vehicle),
      clutchPlan: plan,
    };

    const billOfSaleValues = getBillOfSaleValues(bosParams);

    //Trade-in values
    // Trade-in credit tax savings is either the STC's tax savings amount, or the
    // vehicleTotalTaxAmountNoTradeIn amount -- whichever is lower.
    // This allows us to handle cases where the trade-in amount is greater than the
    // cost of the vehicle being purchased.
    const tradeInTaxSavings = plan.isTradeInAvailable
      ? Math.min(tradeInTaxSavingsInput, billOfSaleValues?.vehicleTotalTaxAmountNoTradeIn)
      : 0;

    //100 is added for deposit placed
    const finalTotal = billOfSaleValues.financeAmount ? billOfSaleValues.financeAmount + 100 : billOfSaleValues.totalPrice + 100 || 0;

    //Financing values for monthly and weekly
    const financingPayments: Record<string, number> = {};

    if (finalTotal > 0) {
      const { biWeekly: preTaxBiWeekly } =
        billOfSaleValues.preTaxTotal > 0 &&
        calculateFinancePayments({
          apr: interestRate,
          months: loanLength,
          totalLoanAmount: billOfSaleValues.preTaxTotal || 0,
        });
      const { biWeekly: purchaseValueBiWeekly } = calculateFinancePayments({
        apr: interestRate,
        months: loanLength,
        totalLoanAmount: billOfSaleValues.clutchPlanSubtotal,
      });

      financingPayments.preTaxBiWeekly = Math.round(preTaxBiWeekly);
      financingPayments.purchaseValueBiWeekly = Math.round(purchaseValueBiWeekly);
    }

    acc[plan.name] = {
      finalPriceIncFees: finalTotal,
      clutchPlanSubtotal: billOfSaleValues.clutchPlanSubtotal,
      clutchPlanSubtotalWithTradeIn: billOfSaleValues.clutchPlanSubtotalWithTradeIn,
      financingPayments,
      tradeInTaxSavings,
      tradeInCredit: billOfSaleValues.tradeInCredit,
    };
    if (finalTotal < 0 || billOfSaleValues.clutchPlanSubtotalWithTradeIn < 0) isAnyPositiveEquity = true;
    return acc;
  }, {});

  return { isAnyPositiveEquity, allPlanPricing };
};

export default useFinanceForPlanPricing;
