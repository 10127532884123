import { useContext, useState, useEffect } from 'react';
import * as R from 'ramda';
import { vehicleStates } from '@clutch/clutch-common/lib/constants';
import * as Sentry from '@sentry/browser';

import {
  LoginSignupModalContext,
  AuthContext,
  VehicleDetailsContext,
  CheckoutContext,
  AnalyticsContext,
  LocationContext,
} from '../contexts';
import { getCheckoutEventProperties } from '../eventFormatter/checkout';
import { useHistory } from 'react-router';
import { ORDER_CONSTANTS, ROUTES } from 'src/static';

const useCheckoutVehicle = () => {
  const { user, isAuthenticated, initiatedCheckout, setAuthOnSuccessPayload, authOnSuccessPayload, isSapUser } = useContext(AuthContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const { vehicle, isLoading: vehicleLoading } = useContext(VehicleDetailsContext);
  const { startCheckout, financeCalculator, answers } = useContext(CheckoutContext);
  const { closestLocation } = useContext(LocationContext);
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const [isLoading, setIsLoading] = useState();
  const history = useHistory();

  const callStartCheckout = async () => {
    try {
      clutchDataLayer.track('Get Started Clicked', {
        action: 'Click',
        details: 'User clicks on Get Started on the VDP',
        payload: {
          ...R.omit(['photos'], vehicle),
          vehicleDetail: R.omit(['id'], vehicle?.vehicleDetail),
        },
        flow: 'checkout',

        ...getCheckoutEventProperties({
          vehicle,
          finalPriceIncFees: financeCalculator.finalPriceIncFees,
          isAuthenticated,
          user,
        }),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
    if (isAuthenticated) {
      if (vehicle?.session?.lockedByRequestingUser && !R.isEmpty(answers)) {
        answers.dealType === ORDER_CONSTANTS.DEAL_TYPE.DEALER
          ? history.replace(ROUTES.DEALER_CHECKOUT)
          : history.replace(ROUTES.RETAIL_CHECKOUT);
      } else {
        setIsLoading(true);
        await startCheckout({ vehicleId: vehicle.id });
        setIsLoading(false);
      }
    } else if (!isAuthenticated) {
      initiatedCheckout.setTrue();

      setAuthOnSuccessPayload({
        type: 'CHECKOUT',
      });
      loginSignupModalContext.onModalOpen({
        shouldRedirectOnSignIn: true,
      });
    }
  };

  const shippingFee = R.path(['shippingFee'], vehicle);
  const deliveryAvailable = R.path(['price'], vehicle) && !R.isNil(shippingFee);

  const disableButton =
    (R.path(['session'], vehicle) && !R.path(['session', 'lockedByRequestingUser'], vehicle)) ||
    isLoading ||
    vehicleLoading.value ||
    [vehicleStates.SALE_PENDING, vehicleStates.SOLD].includes(R.path(['websiteState'], vehicle)) ||
    !deliveryAvailable ||
    !R.path(['price'], vehicle);

  useEffect(async () => {
    const { type } = authOnSuccessPayload;
    if (
      type === 'CHECKOUT' &&
      isAuthenticated &&
      !isSapUser &&
      // race condition between user preferred location propagating to closestLocation and moving to checkout
      user?.preferredLocation?.closestLocationId === closestLocation?.id
    ) {
      setIsLoading(true);
      await startCheckout({ vehicleId: vehicle.id });
      setAuthOnSuccessPayload({});
      setIsLoading(false);
    }
  }, [isAuthenticated, closestLocation?.id]);

  return {
    callStartCheckout,
    deliveryAvailable,
    disableButton,
    isLoading,
    isComingSoon: R.path(['websiteState'], vehicle) === vehicleStates.COMING_SOON,
    isReserved: R.path(['websiteState'], vehicle) === vehicleStates.SALE_PENDING,
  };
};

export default useCheckoutVehicle;
