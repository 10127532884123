import { Sections } from './retailSections';
import { Steps } from './retailSteps';
import { RetailCheckoutStepTree } from './retailStepTree';

export const RetailSectionMap = {
  [Sections.TRADE_IN]: {
    key: Sections.TRADE_IN,
    label: Sections.TRADE_IN,
    startKey: Steps.SELL_TO_CLUTCH,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.TRADE_IN),
  },
  [Sections.BUYER_INFO]: {
    key: Sections.BUYER_INFO,
    label: Sections.BUYER_INFO,
    startKey: Steps.BUYER_INFORMATION,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.BUYER_INFO),
  },
  [Sections.FINANCE_OR_CASH]: {
    key: Sections.FINANCE_OR_CASH,
    label: Sections.FINANCE_OR_CASH,
    startKey: Steps.PAYMENT_TYPE,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.FINANCE_OR_CASH),
  },
  [Sections.PROTECTION_PLANS]: {
    key: Sections.PROTECTION_PLANS,
    label: Sections.PROTECTION_PLANS,
    startKey: Steps.PROTECTION_CUSTOMIZATION,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.PROTECTION_PLANS),
  },
  [Sections.SCHEDULE]: {
    key: Sections.SCHEDULE,
    label: Sections.SCHEDULE,
    startKey: Steps.RECEPTION_TYPE,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.SCHEDULE),
  },
  [Sections.DRIVERS_LICENSE]: {
    key: Sections.DRIVERS_LICENSE,
    label: Sections.DRIVERS_LICENSE,
    startKey: Steps.DRIVERS_LICENSE,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.DRIVERS_LICENSE),
  },
  [Sections.REVIEW]: {
    key: Sections.REVIEW,
    label: Sections.REVIEW,
    startKey: Steps.REVIEW,
    steps: [Steps.REVIEW],
  },
};

export const ClutchPlanRetailSectionMap = {
  [Sections.TRADE_IN]: {
    key: Sections.TRADE_IN,
    label: Sections.TRADE_IN,
    startKey: Steps.SELL_TO_CLUTCH,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.TRADE_IN),
  },
  [Sections.BUYER_INFO]: {
    key: Sections.BUYER_INFO,
    label: Sections.BUYER_INFO,
    startKey: Steps.BUYER_INFORMATION,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.BUYER_INFO),
  },
  [Sections.CLUTCH_PLAN]: {
    key: Sections.CLUTCH_PLAN,
    label: Sections.CLUTCH_PLAN,
    startKey: Steps.CLUTCH_PLAN,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.CLUTCH_PLAN),
  },
  [Sections.FINANCE_OR_CASH]: {
    key: Sections.FINANCE_OR_CASH,
    label: Sections.FINANCE_OR_CASH,
    startKey: Steps.PAYMENT_TYPE,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.FINANCE_OR_CASH),
  },
  [Sections.GAP_SELECT]: {
    key: Sections.GAP_SELECT,
    label: Sections.GAP_SELECT,
    startKey: Steps.GAP_SELECT,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.GAP_SELECT),
  },
  [Sections.SCHEDULE]: {
    key: Sections.SCHEDULE,
    label: Sections.SCHEDULE,
    startKey: Steps.RECEPTION_TYPE,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.SCHEDULE),
  },
  [Sections.DRIVERS_LICENSE]: {
    key: Sections.DRIVERS_LICENSE,
    label: Sections.DRIVERS_LICENSE,
    startKey: Steps.DRIVERS_LICENSE,
    steps: Object.values(Steps).filter(step => RetailCheckoutStepTree[step].section === Sections.DRIVERS_LICENSE),
  },
  [Sections.REVIEW]: {
    key: Sections.REVIEW,
    label: Sections.REVIEW,
    startKey: Steps.REVIEW,
    steps: [Steps.REVIEW],
  },
};
