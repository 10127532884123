import { MoneyMask } from 'src/helpers/masks';

import * as Styled from '../OrderDetails.styles';

type Incentive = {
  isReferral: boolean;
  incentiveAmount: number;
  company: {
    name: string;
  };
  incentiveGroup: {
    company: {
      name: string;
    };
    incentiveAmounts: {
      type: string;
      refereeReward: number;
    }[];
  };
};

export const Incentive = ({ incentive, finalPriceIncFees }: { incentive: Incentive; finalPriceIncFees: number }) => {
  const isVehicleHoldAfterTax = finalPriceIncFees < 0;

  if (!incentive) return null;

  const formattedIncentive = {
    isReferral: incentive.isReferral,
    company: incentive.incentiveGroup?.company,
    incentiveAmount: incentive.incentiveGroup?.incentiveAmounts?.find(
      (incentiveAmount: any) => !!incentiveAmount.refereeReward && incentiveAmount.type === 'ORDER_PLACED',
    )?.refereeReward,
  };

  return (
    <Styled.BreakdownHeaderRow>
      <Styled.BreakdownHeader>
        {formattedIncentive?.isReferral ? 'Referral Reward' : `${formattedIncentive?.company?.name} Discount`}
      </Styled.BreakdownHeader>
      <Styled.BreakdownHeader>{`${isVehicleHoldAfterTax ? '' : '-'}${MoneyMask(
        formattedIncentive.incentiveAmount,
      )}`}</Styled.BreakdownHeader>
    </Styled.BreakdownHeaderRow>
  );
};
