import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';

export const BreakdownHeaderRow = muiStyled('div')<{ expand?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ expand }) => (expand ? '0px' : '8px')};
`;

export const BreakdownHeader = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const BreakdownButton = styled.button`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  border: none;
  background: none;
  text-decoration: underline;
`;

export const BreakdownItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const BreakdownItemRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BreakdownItemText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
`;

export const BreakdownSubItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

export const TotalSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const TotalRow = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
`;

export const TotalText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const TotalTextNote = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
`;

export const FooterText = styled.span`
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
`;

export const FinancingDetailsCopy = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
`;
